import {Word} from "../Word";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addFilter, selectFilter,
    selectSortTypes, selectTerms, setTerm
} from "../../features/supplyFilters/supplyFiltersSlice";
import {FormElementCheckbox} from "../Form/Element/FormElementCheckbox";
import {FormElementRadio} from "../Form/Element/FormElementRadio";

/**
 *
 * @param separate
 * @param identifier
 * @return {JSX.Element}
 * @constructor
 */
export function SupplyFilter({separate = true, identifier}) {

    const filter = useSelector((state) => selectFilter(state, identifier));

    const dispatch = useDispatch();

    if (!filter) {
        dispatch(addFilter({
            identifier: identifier
        }));
    }

    const sortTypes = useSelector(selectSortTypes),
        terms = useSelector((state) => selectTerms(state, identifier));

    const handleChange = (e) => {

        const {target} = e;
        let value = target.value;

        if (target.getAttribute("type") === "checkbox") {
            value = target.checked;
        }

        dispatch(setTerm({
            identifier: identifier,
            name: target.getAttribute("name"),
            value: value
        }));
    }

    return (
        <React.Fragment>

            <div className="form__component form__component--fieldset form__component--fieldset--vertical">
                <div className="form__component__column">
                    <div className="form__element">

                        <FormElementCheckbox
                            attr={{
                                id: "showZeroQuantity",
                                name: "showZeroQuantity",
                                checked: terms["showZeroQuantity"] ?? false,
                                onChange: handleChange
                            }}
                            content={<Word tag={"SUPPLYFILTERS-SHOWZEROQUANTITY"}/>}
                        />
                    </div>
                </div>
                <div className="form__component__column">
                    <div className="form__element">

                        <FormElementCheckbox
                            attr={{
                                id: "directDelivery",
                                name: "directDelivery",
                                checked: terms["directDelivery"] ?? false,
                                onChange: handleChange
                            }}
                            content={<Word tag={"SUPPLYFILTERS-DIRECTDELIVERY"}/>}
                        />
                    </div>
                </div>
            </div>

            {separate === true ? <hr /> : ""}

            <div className="form__component form__component--fieldset form__component--fieldset--vertical">

                {sortTypes.map(type => {

                    if (type.contexts.includes(identifier) === false) {
                        return "";
                    }

                    if (type.types && type.types.length > 0) {

                        return type.types.map(sort => {

                            return (
                                <div className="form__component__column">
                                    <div className="form__element">

                                        <FormElementRadio
                                            attr={{
                                                id: type.key + "-" + sort,
                                                name: "sort",
                                                value: type.key + "-" + sort,
                                                defaultChecked: terms['sort'] === type.key + "-" + sort,
                                                onChange: handleChange
                                            }}
                                            content={<Word tag={"SUPPLYFILTERS-SORT-" + type.key.toUpperCase() + "-" + sort.toUpperCase()}/>}
                                        />
                                    </div>
                                </div>
                            )
                        });
                    } else {

                        // checked = this.state.formData.filters.sort === type.key;

                        return (
                            <div className="form__component__column">
                                <div className="form__element">

                                    <FormElementRadio
                                        attr={{
                                            id: type.key,
                                            name: "sort",
                                            value: type.key,
                                            defaultChecked: terms['sort'] === type.key,
                                            onChange: handleChange
                                        }}
                                        content={<Word tag={"SUPPLYFILTERS-SORT-" + type.key.toUpperCase()}/>}
                                    />
                                </div>
                            </div>
                        )
                    }

                })}

            </div>

        </React.Fragment>
    );


}
