import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import "./scss/stylesheet.scss";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import store from './app/store';

import {Provider} from 'react-redux';
import swConfig from "./swConfig";
import {BrowserRouter} from "react-router-dom";
import {Alerts} from "./features/alerts/Alerts";

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>

    </React.StrictMode>
);

createRoot(document.getElementById('appAlerts')).render(
    <React.StrictMode>
        <Provider store={store}>
            <Alerts identifier={"appErrors"}/>
        </Provider>

    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(swConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
