import React, {Fragment} from 'react';
import AppContainer from "../components/AppContainer";
import {HeaderDefault} from "../layout/Header";
import {Content} from "../components/Content";
import {Identity} from "../components/Identity";
import {Word} from "../components/Word";
import {useNavigate} from "react-router-dom";

export function Page500() {

    const navigate = useNavigate();

    return (
        <Fragment>

            <Identity/>

            <AppContainer>

                <HeaderDefault/>

                <Content>
                    <h1 className="c-secondary"><Word tag={"ERROR-OCCURRED"}/></h1>
                </Content>

                <Content>
                    <Word tag={"ERROR-OCCURRED-RETURN-MESSAGE"} paragraph={true}/>
                </Content>
                <Content>
                    <button type={"button"} className={"button button--primary button--readmore"}
                            onClick={() => navigate("/")}>
                        <Word tag={"ERROR-OCCURRED-RETURN-BUTTON-TEXT"}/>
                    </button>
                </Content>

            </AppContainer>

        </Fragment>
    )

}
