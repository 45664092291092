import React, {useCallback} from "react";
import {useFormErrorClass} from "../../FormFromArray";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {Autocomplete, TextField, ThemeProvider} from "@mui/material";
import {theme} from "../../../app/mui-themes";

/**
 *
 * @param attr
 * @param options
 * @param hasError
 * @param field
 * @param setValue
 * @param getValues
 * @return {JSX.Element}
 * @constructor
 */
export function FormElementSelect({attr, options, hasError, field, formHandlers: {setValue, getValues}}) {

    const className = useFormErrorClass(attr, hasError);
    const storedValue = getValues(attr.name);
    const multiple = !!attr.multiple;

    const getOptionsByIds = useCallback((ids) => {

        let defOption = null;

        if (!ids && options.length > 0) {
            if (multiple === false) {
                ids = '';
            } else {
                ids = [];
            }
        }

        if (ids !== typeof undefined) {

            if (multiple === true) {
                defOption = [];
            }

            options.forEach((option) => {
                if (multiple === false) {
                    if (String(ids) === String(option.value)) {
                        defOption = option;
                    }
                } else {
                    if (ids.includes(String(option.value))) {
                        defOption = [...defOption, option];
                    }
                }
            })
        }

        return defOption;

    });

    let defOptions = [];

    options.map((option) => {

        if (typeof option.display !== "string") {
            return false
        }

        if (multiple !== false && option.value === "") {
            return false;
        }

        defOptions = [...defOptions, option];
    });

    return (
        <ThemeProvider theme={theme}>
            <Autocomplete
                multiple={multiple}
                id={attr.id}
                getOptionLabel={(option) => option.display}
                options={defOptions}
                defaultValue={getOptionsByIds(storedValue)}
                fullWidth={true}
                sx={{
                    '.MuiAutocomplete-tag': {
                        marginRight: theme.spacing(0.5),
                        marginBottom: theme.spacing(0.5),
                        marginLeft: 0,
                        marginTop: 0,
                        maxWidth: "calc(100% - 4px)",
                    },
                    '.MuiAutocomplete-endAdornment': {
                        bottom: 1,
                        top: "auto",
                        width: "72px",
                        display: "flex",
                        justifyContent: "flex-end",
                        'button': {
                            width: "36px",
                            height: "36px",
                            display: "block",
                            fontSize: "1rem",
                            padding: "0",
                            margin: 0,
                            boxSizing: "border-box",
                            borderRadius: 0
                        },
                        '.MuiAutocomplete-clearIndicator': {
                            color: theme.palette.grey.main
                        }
                    },
                    '.MuiInput-root .MuiInput-input': {
                        padding: "5px 12px"
                    },
                    ['&.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiInput-root,' +
                    '&.MuiAutocomplete-hasPopupIcon .MuiInput-root']: {
                        paddingRight: "0",
                        display: "block",
                        position: "relative"
                    },
                }}
                popupIcon={<div
                    className={"dropdown-handle h-100 " + (hasError ? "bg-danger" : "bg-grey") + " text-white d-flex align-items-center justify-content-center"}>
                    <FontAwesomeIcon icon={faCaretDown}/>
                </div>}
                renderInput={params => {

                    params.inputProps.className = params.inputProps.className + className;

                    return <TextField
                        {...params}
                        name={field.name}
                        color={hasError ? "error" : "secondary"}
                        variant="standard"/>;
                }}
                ListboxProps={{
                    sx: {
                        '.MuiAutocomplete-option:hover': {
                            background: theme.palette.secondary.main,
                            color: theme.palette.secondary.contrastText
                        }
                    }
                }}
                ChipProps={{
                    color: "secondary",
                    sx: {
                        borderRadius: "0",
                        color: "white",
                        '& .MuiChip-deleteIcon': {
                            color: 'white',
                        },
                    }
                }}
                onChange={(event, selected, reason) => {

                    let newValue;

                    if (reason === "clear") {
                        newValue = "";
                    } else {
                        if (multiple === false) {
                            newValue = String(selected.value);
                        } else {
                            newValue = Array.from(selected, option => String(option.value));
                        }
                    }

                    /*
                    Set the form value for this field (should work since it's not registered)
                     */
                    setValue(attr.name, newValue);
                }}
            />
        </ThemeProvider>
    )
}
