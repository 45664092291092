import {configureStore} from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import autocompleteReducer from '../features/autocomplete/autocompleteSlice';
import popupReducer from "../features/popup/popupSlice";
import supplyFiltersReducer from "../features/supplyFilters/supplyFiltersSlice";
import lightboxReducer from "../features/lightbox/lightboxSlice";
import alertsReducer from "../features/alerts/alertsSlice";
import translateReducer from "../features/translate/translateSlice";
import pwabannerReducer from "../features/Pwabanner/pwabannerSlice";
import listReducer from "../features/list/listSlice";

export default configureStore({
    reducer: {
        user: userReducer,
        alerts: alertsReducer,
        autocomplete: autocompleteReducer,
        popup: popupReducer,
        supplyFilters: supplyFiltersReducer,
        lightbox: lightboxReducer,
        translate: translateReducer,
        pwabanner: pwabannerReducer,
        list: listReducer
    },
});
