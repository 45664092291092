import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import ListItem from "../components/List/ListItem";
import Autocomplete from "../features/autocomplete/Autocomplete";

import {fetchSupplyImages, PAGE_TITLE_BASE, withNavigate} from "../app/config";
import AppContainer from "../components/AppContainer";
import {HeaderDefault} from "../layout/Header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCheck, faClipboard, faPlus, faRedoAlt,} from "@fortawesome/free-solid-svg-icons";
import API, {IMAGE_BASE} from "../API";
import {
    selectSelected,
    setSelected
} from "../features/autocomplete/autocompleteSlice";
import {faImage, faImages} from "@fortawesome/free-regular-svg-icons";
import {SupplyFilters} from "../features/supplyFilters/SupplyFilters";
import {selectTerms, setActive, setTerm} from "../features/supplyFilters/supplyFiltersSlice";
import {ListItemRow} from "../components/List/ListItemRow";
import {Identity} from "../components/Identity";
import {Content} from "../components/Content";
import {SuppliesList} from "../components/Supplies/SuppliesList";
import {selectWord} from "../features/translate/translateSlice";
import {Word} from "../components/Word";
import {setList} from "../features/list/listSlice";
import {addAlert} from "../features/alerts/alertsSlice";

/**
 * Supply class
 */
class Supply extends Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            supplyId: null,
            formData: {
                context: "IDENTITY",
                terms: "",
                index: 0,
                resultCount: 0,
            },
            lightbox: {
                open: false,
                images: []
            }
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);

        this.autocompleteRef = React.createRef();

    }

    /**
     *
     * @param query
     * @returns {boolean}
     */
    handleSelect = (query) => {

        if (!query[0]) {
            return false;
        }

        this.autocompleteRef.current.blur();

        this.props.dispatch(setSelected({
            identifier: this.state.formData.context,
            value: query
        }));

        this.props.dispatch(setList({
            identifier: this.state.formData.context,
            triggerRefresh: true
        }));

        this.setState({
            formData: {
                ...this.state.formData,
                terms: this.autocompleteRef.current.inputNode.value,
                index: this.autocompleteRef.current.inputNode.value.length
            }
        });
    }


    /**
     *
     * @returns {Promise<void>}
     */
    componentDidMount = () => {

        const selected = selectSelected(this.props, this.state.formData.context);
        const routeTitle = selectWord(this.props, "ROUTE-SUPPLY-TITLE");
        const terms = selectTerms(this.props, this.state.formData.context);

        document.title = routeTitle + PAGE_TITLE_BASE

        if (selected && selected[0]) {
            this.setState({
                formData: {
                    ...this.state.formData,
                    terms: selected[0].repeatSearchQuery,
                }
            });
        }

        // also set zero supply to on
        if (terms.length < 1) {
            this.props.dispatch(setTerm({
                identifier: this.state.formData.context,
                name: "showZeroQuantity",
                value: true
            }));
        }

        this.setState({
            isLoaded: true,
        });

    }

    /**
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.formData.terms !== ""
            && selectSelected(prevProps, this.state.formData.context) !== false
            && selectSelected(this.props, this.state.formData.context) === false) {

            this.setState({
                formData: {
                    ...this.state.formData,
                    terms: "",
                    index: 0,
                }
            });
        }

        sessionStorage.removeItem("supplyList");
    }

    /**
     *
     * @param event
     * @return {boolean}
     */
    handleSubmit = event => {

        event.preventDefault();

        this.props.dispatch(setActive(false));
        this.props.dispatch(setList({
            identifier: this.state.formData.context,
            triggerRefresh: true
        }));

        const form = event.target;

        if (!form) {
            return false;
        }

        this.setState({
            error: null,
            formData: {
                ...this.state.formData,
                terms: this.autocompleteRef.current.inputNode.value,
                index: this.autocompleteRef.current.inputNode.value.length
            }
        });

    }

    handleEdit = (ev, supplyId, setStoredList) => {
        setStoredList(ev);
        this.props.navigate(this.props.location.pathname + "/" + supplyId + "/bewerken")
    }

    handleCamera = (ev, supplyId, setStoredList) => {
        setStoredList(ev);
        this.props.navigate(this.props.location.pathname + "/" + supplyId + "/gallerij")
    }


    /**
     *
     * @param item
     * @param index
     * @param onClickCallback
     * @param setStoredList
     * @return {JSX.Element}
     */
    listItem = (item, index, onClickCallback, setStoredList) => {

        let actionsLeft = [],
            actionsRight = [
                {
                    identifier: "camera",
                    icon: <FontAwesomeIcon icon={faCamera}/>,
                    label: <Word tag={"SUPPLY-LIST-ACTION-CAMERA"}/>,
                    extraClass: "text-primary bg-white",
                    onClick: (ev) => this.handleCamera(ev, item.supplyId, setStoredList)
                },
                {
                    identifier: "refresh",
                    extraClass: "bg-secondary",
                    icon: <FontAwesomeIcon icon={faRedoAlt}/>,
                    label: <Word tag={"SUPPLY-LIST-ACTION-REFRESH"}/>,
                    onClick: this.handleRefresh
                },
                {
                    identifier: "edit",
                    extraClass: "bg-primary",
                    icon: <FontAwesomeIcon icon={faCheck} mask={faClipboard} transform={"shrink-9 down-3"}/>,
                    label: <Word tag={"SUPPLY-LIST-ACTION-EDIT"}/>,
                    onClick: (ev) => this.handleEdit(ev, item.supplyId, setStoredList)
                }
            ];


        if (item.thumbnail) {
            actionsLeft = [<img
                decoding={"sync"}
                src={IMAGE_BASE + "/guid/" + item.thumbnail + "/type/preview-existing/directive/scalearwithin-100x66"}
                alt={"Thumbnail " + item.title}
                onClick={() => {
                    this.setState({supplyId: item.supplyId}, () => fetchSupplyImages(this.state.supplyId))
                }}/>
            ];
        }

        let photoIcon = <span className="icon me-1 text-danger"><FontAwesomeIcon icon={faImage}/></span>;

        if (parseInt(item.hasPhoto) > 1) {
            photoIcon = <span className="icon me-1"><FontAwesomeIcon icon={faImages}/></span>;

        } else if (parseInt(item.hasPhoto) === 1) {
            photoIcon = <span className="icon me-1"><FontAwesomeIcon icon={faImage}/></span>;
        }

        const quantityStr = (item.quantity !== "" ? parseFloat(item.quantity) : "");

        return (
            <ListItem data-supply-id={item.supplyId}
                      actions={{left: actionsLeft, right: actionsRight}}>

                <div onClick={onClickCallback}>

                    <div className="results__overview__list__item__row">
                        <div className="results__overview__list__item__text fw-bold">
                            {photoIcon}
                            {item.title}
                        </div>
                        <div className="results__overview__list__item__text"/>
                    </div>

                    <ListItemRow label={item.displayQuality} value={quantityStr.toLocaleString("nl-NL")}/>
                    <ListItemRow
                        label={<Fragment><Word tag={"SUPPLY-LIST-LABEL"}/>: <Word
                            tag={"LABEL-" + (parseInt(item.hasLabel) === 1 ? "YES" : "NO")}/></Fragment>}
                        value={item.price || <Word tag={"SUPPLY-LIST-ON-REQUEST"}/>}/>

                </div>

            </ListItem>
        );
    }

    /**
     *
     * @param event
     * @return {boolean}
     */
    handleRefresh = event => {

        event.preventDefault();

        const target = event.target;
        const wrap = target.closest(".results__overview__list__item");

        if (!wrap) {
            return false;
        }

        let supplyId = wrap.getAttribute("data-supply-id");

        if (supplyId) {
            supplyId = parseInt(supplyId);
        }

        API.refreshSupplies(this.state.formData.context, [supplyId])
            .then((res) => {
                if (res.status !== 200 || !res.data || (res.data && res.data.status !== null)) {
                    if (res.data && res.data.message) {
                        throw new Error(res.data.message);
                    }
                }

                this.props.dispatch(addAlert({
                    identifier: "suppliesAlerts",
                    type: res.data.status === "OK" ? "success" : "danger",
                    message: res.data.status === "OK" ? "Succesvol ververst" : res.data.message,
                }));

                const data = res.data;
                if (data.data[supplyId]) {

                    wrap.querySelector(".results__overview__list__item__inner").classList.remove("results__overview__list__item__inner--moveleft");
                    wrap.querySelector(".results__overview__list__item__inner").classList.remove("results__overview__list__item__inner--moveright");
                    wrap.querySelector(".notification__actions").classList.remove("notification__actions--moveleft");
                    wrap.querySelector(".notification__actions").classList.remove("notification__actions--moveright");

                }

            })
            .catch((error) => {
                console.error("Refresh update for " + supplyId + " failed:", error.message);
            });
    }

    render() {

        const {error} = this.state;
        const forwardedRef = this.autocompleteRef
        const autocompleteHandle = <Autocomplete
            forwardedRef={forwardedRef}
            context={this.state.formData.context}
            onChange={this.handleSelect}
            onSubmit={this.handleSubmit}
        />

        const supplyFilters = <SupplyFilters
            handleSubmit={this.handleSubmit}
            defaults={{showZeroQuantity: 1}}
            identifier={this.state.formData.context}
            autocompleteHandle={autocompleteHandle}
        />;

        let content;

        if (error) {
            content = <Content><p>Error: {error.message}</p></Content>;
        } else if (this.state.isLoaded !== false) {
            content = <SuppliesList
                allowSelectedFalse={true}
                supplyId={this.state.supplyId}
                handleListItem={this.listItem}
                formData={this.state.formData}
                postOnClick={(supplyId) => this.props.navigate(`${this.props.location.pathname}/${supplyId}/snel-bewerken`)}
            />
        }

        const headerAction = <button
            onClick={() => this.props.navigate(`${this.props.location.pathname}/toevoegen`)}
            className={"button bg-secondary text-white d-block p-1 h-auto"}>
            <span className={"me-1"}><FontAwesomeIcon icon={faPlus}/></span>Toevoegen
        </button>;

        return (
            <Fragment>
                <Identity/>

                <AppContainer>

                    <HeaderDefault action={headerAction} />

                    {supplyFilters}

                    {content}

                </AppContainer>
            </Fragment>
        );

    }

}

const MapStateToProps = (state) => ({
    user: {...state.user},
    autocomplete: state.autocomplete,
    supplyFilters: state.supplyFilters,
    translate: state.translate
});

export default connect(MapStateToProps)(withNavigate(Supply));
