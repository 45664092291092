import React from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const SortableItem = SortableElement(({sortIndex, value, itemActions}) => {

    return (
        <div className={"col"}>

            <div className="position-relative">
                <div className={"position-relative"} style={{zIndex: 98}}>
                    {value}
                </div>

                <div className="image__wrapper--actions position-absolute" style={{zIndex: 99, top: ".5rem", right: ".5rem"}}>
                    <button type={"button"} className={"btn btn-light btn-sm ms-1"} onClick={() => itemActions.onDelete(sortIndex)}>
                        <FontAwesomeIcon icon={faTrash} style={{
                            pointerEvents: "none"
                        }}/>
                    </button>
                </div>
            </div>
        </div>
    );
});

const SortableList = SortableContainer(({items, addHandler, itemActions}) => (
    <div className={"row row-cols-3 row-cols-md-6 g-2"}>
        {items.map((value, index) => (
            <SortableItem
                key={"item-" + index}
                index={index}
                sortIndex={index}
                value={value}
                itemActions={itemActions}
            />
        ))}
        <div className="col">{addHandler}</div>
    </div>
));

/**
 *
 * @param items
 * @param addHandler
 * @param itemActions
 * @param onSortEnd
 * @return {JSX.Element}
 * @constructor
 */
export function DragList({items, addHandler, itemActions, onSortEnd}) {

    return <SortableList
        axis="xy"
        items={items}
        onSortEnd={onSortEnd}
        addHandler={addHandler}
        itemActions={itemActions}
    />

}
