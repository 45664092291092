import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {setLocalStorage, setToken, setUser} from '../../features/user/userSlice';
import {HeaderDefault} from "../../layout/Header";
import API from "../../API";
import {Content} from "../../components/Content";
import {addAlert} from "../../features/alerts/alertsSlice";
import {Alerts} from "../../features/alerts/Alerts";
import {PAGE_TITLE_BASE, pageViewsTracking} from "../../app/config";
import {Word} from "../../components/Word";
import {Pwabanner} from "../../features/Pwabanner/Pwabanner";
import {withNavigate} from "../../app/config";
import {FormFromArray} from "../../components/FormFromArray";
import {selectWord} from "../../features/translate/translateSlice";
import {Link} from "react-router-dom";

/**
 * AuthLogin class
 */
class AuthLogin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: [
                {
                    attributes: {
                        id: "username",
                        type: "email",
                        name: "username",
                        placeholder: selectWord(this.props, "PLACEHOLDER-LOGIN-USERNAME"),
                    },
                    label: <Word tag={"LABEL-LOGIN-USERNAME"}/>,
                    required: true,
                },
                {
                    attributes: {
                        id: "password",
                        type: "password",
                        name: "password",
                        placeholder: selectWord(this.props, "PLACEHOLDER-LOGIN-PASSWORD"),
                    },
                    label: <Word tag={"LABEL-LOGIN-PASSWORD"}/>,
                    required: true,
                },
                {
                    attributes: {
                        id: "rememberMe-0",
                        type: "checkbox",
                        name: "rememberMe",
                    },
                    label: "",
                    content: <Word tag={"LABEL-LOGIN-REMEMBER"}/>,
                }

            ]
        };


        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {
        document.title = "Inloggen" + PAGE_TITLE_BASE;

        pageViewsTracking();
    }

    handleSubmit = (data) => {

        const formData = {
            emailaddress: data.username,
            password: data.password,
            context: "IDENTITY"
        };

        API.userLogin(formData)
            .then((res) => {

                const {token, username, message, status} = res.data;
                if (status !== "OK") {
                    throw new Error(message);
                }

                this.props.dispatch(setUser(username))
                this.props.dispatch(setToken(token))

                if (data.rememberMe === true) {
                    this.props.dispatch(setLocalStorage())
                }

                this.props.navigate("/eigen-aanbod")

            })
            .catch((err) => {

                this.props.dispatch(addAlert({
                    identifier: "auth-alerts",
                    type: "danger",
                    message: err.message
                }));

            });
    };

    componentWillUnmount() {
        document.body.classList.remove('template-login');
    }

    /**
     * Render
     */
    render() {

        document.body.classList.add('template-login');

        return (
            <Fragment>
                <div className="outer-wrapper">
                    <div className="inner-wrapper">

                        <HeaderDefault/>

                        <Content>
                            <h1 className="mb-0"><Word tag={"HEADER-LOGIN-TITLE"}/></h1>
                        </Content>

                        <Content>

                            <Alerts identifier={"auth-alerts"} disableClear={true}/>

                            <p><Word tag={"LABEL-LOGIN-DESCR"}/></p>

                            <FormFromArray
                                fields={this.state.fields}
                                prefill={{rememberMe: true}}
                                handlers={{onSubmit: this.handleSubmit, onError: false}}
                                formProps={{autoComplete: "off"}}
                                customSubmit={
                                    <div
                                        className={"form__component form__component--buttons form__component--fieldset"}>

                                        <div className={"form__component__column"}>
                                            <div className={"form__element"}>
                                                <button className={"button button--primary button--readmore"} type={"submit"}>
                                                    <Word tag={"BUTTON-LOGIN"}/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className={"form__component__column mt-2 mt-md-0"}>
                                            <div className={"form__element"}>
                                                <Link to={"/auth/forgot"} className={"read-more"}>
                                                    <Word tag={"BUTTON-AUTH-FORGOT"}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                }/>

                        </Content>

                    </div>
                </div>

                <Pwabanner/>

            </Fragment>
        );
    };
}

const MapStateToProps = (state) => ({
    user: state.user,
    alerts: state.alerts,
    translate: state.translate,
});

export default connect(MapStateToProps)(withNavigate(AuthLogin));
