import {createSlice} from '@reduxjs/toolkit';

export const PopupSlice = createSlice({
    name: 'popup',
    initialState: {
        status: "hidden"
    },
    reducers: {
        setStatus: (state = this.initialState, action) => {
            state.status = action.payload;
        }
    },
});

export const {setStatus} = PopupSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectStatus = state => state.popup.status;

export default PopupSlice.reducer;
