import {createSlice} from '@reduxjs/toolkit';

export const ListSlice = createSlice({
    name: 'list',
    initialState: {},
    reducers: {
        addList: (state = this.initialState, action) => {

            const {identifier} = action.payload;
            state[identifier] = {
                pages: [],
                triggerRefresh: false,
                currentPage: 1,
                supplyId: null,
                offset: {
                    x: 0,
                    y: 0
                }
            };
        },
        triggerRefresh: (state = this.initialState, action) => {
            const {identifier, ...value} = action.payload;
            state[identifier] = {
                ...state[identifier],
                triggerRefresh: value
            };
        },
        setList: (state = this.initialState, action) => {
            const {identifier, ...value} = action.payload;
            state[identifier] = value;
        },
        editListItem: (state = this.initialState, action) => {
            const {identifier, itemId, value} = action.payload;

            if (state[identifier] && state[identifier]["pages"][itemId]) {
                state[identifier]["pages"][itemId] = {
                    ...state[identifier]["pages"][itemId],
                    ...value
                };
            }

        },
        removeListItem: (state = this.initialState, action) => {
            const {identifier} = action.payload;
            const list = state[identifier]["pages"];

            state[identifier]["pages"] = list;
            state[identifier]["totalResults"] = list.length;
        },

    },
});

export const {addList, triggerRefresh, setList, editListItem, removeListItem} = ListSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectLists = (state) => state.list;
export const selectList = (state, identifier) => state.list[identifier];
export const selectOptions = (state, identifier) => state.list[identifier].options;
export const selectOffset = (state, identifier) => state.list[identifier].offset;
export const selectSupplyId = (state, identifier) => state.list[identifier].supplyId;

export default ListSlice.reducer;
