import React, {useEffect, useState, Fragment} from "react";
import AppContainer from "../../components/AppContainer";
import {useNavigate, useParams} from "react-router-dom";
import {HeaderReturn} from "../../layout/Header";
import API from "../../API";
import {Content} from "../../components/Content";
import {useDispatch} from "react-redux";
import {addAlert} from "../../features/alerts/alertsSlice";
import {setStatus} from "../../features/popup/popupSlice";
import {SupplyView} from "../../components/Supply/SupplyView";
import {PAGE_TITLE_BASE} from "../../app/config";
import {Identity} from "../../components/Identity";
import {FormFromArray} from "../../components/FormFromArray";

export function SearchRequest(props) {

    let {supplyId, requestType} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [supply, setSupply] = useState(null);

    useEffect(() => {
        setIsLoading(true);

        const fetchData = async () => {
            await API.fetchSearchRequestForm(supplyId, requestType)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        setData(res.data.data);
                    }
                })

            await API.fetchSupply(supplyId)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        setSupply(res.data);
                    }
                })

        }

        fetchData();

        setIsLoading(false);

    }, [setData, setSupply, supplyId, requestType])

    const header = <HeaderReturn
        handleOnClick={() => {
            dispatch(setStatus("hidden"));
            navigate("/zoeken/resultaten/" + supplyId)
        }}
    />

    let title;
    switch (requestType.toUpperCase()) {
        case "BUY" :
            title = "Koopbevestiging";
            break;
        case "INFO" :
        default:
            title = "Prijsaanvraag";
            break;
        case "IMAGE" :
            title = "Fotoaanvraag";
            break;
        case "RELATION":
            title = "Relatieverzoek";
            break;
    }

    useEffect(() => {
        document.title = title + PAGE_TITLE_BASE
    }, [title])


    if (isLoading === true || !data || !supply) {
        return (
            <Fragment>

                <Identity/>

                <AppContainer>

                    {header}

                    <Content>
                        <h1 className={"mb-0"}>{title}</h1>
                    </Content>


                </AppContainer>

            </Fragment>
        );
    }

    const handleSubmit = (data) => {
        API.sendSearchRequestForm(supplyId, requestType, data)
            .then((res) => {

                if (res.data.status === "OK") {

                    dispatch(addAlert({
                        identifier: "suppliesAlerts",
                        type: "success",
                        message: title + " succesvol verzonden"
                    }));

                    dispatch(setStatus("hidden"));
                    navigate("/zoeken/resultaten");

                } else {
                    dispatch(addAlert({
                        identifier: "formAlerts",
                        type: "danger",
                        message: "FORM-ERROR-GENERALMESSAGE"
                    }));

                }
            });

    }

    return (
        <Fragment>

            <Identity/>

            <AppContainer>

                {header}

                <Content>
                    <h1 className={"mb-0"}>{title}</h1>
                </Content>

                <SupplyView data={supply}/>

                <Content>

                    <FormFromArray
                        fields={data}
                        handlers={{onSubmit: handleSubmit}}
                    />

                </Content>

            </AppContainer>
        </Fragment>

    );

}
