import {createSlice} from '@reduxjs/toolkit';

export const AutocompleteSlice = createSlice({
    name: 'autocomplete',
    initialState: {},
    reducers: {
        addAutocomplete: (state = this.initialState, action) => {

            const {identifier} = action.payload;
            state[identifier] = {
                selected: [],
            };
        },
        setSelected: (state = this.initialState, action) => {
            const {identifier, value} = action.payload;
            state[identifier].selected = value;
        }
    },
});

export const {addAutocomplete, setSelected} = AutocompleteSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectAutocomplete = (state, identifier) => state.autocomplete[identifier];
export const selectSelected = (state, identifier) => {

    if (state.autocomplete[identifier]
        && typeof state.autocomplete[identifier].selected !== 'undefined') {

        return state.autocomplete[identifier].selected;
    }
}

export default AutocompleteSlice.reducer;
