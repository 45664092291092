import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#00a49b",
            contrastText: "#fff"
        },
        secondary: {
            main: "#8fd26b",
            contrastText: "#fff"
        },
        danger: {
            main: "#ff4338",
        },
        success: {
            main: "#8fd26b",
        },
        blue: {
            main: "#4285f4",
            dark: "#1145e9",
            contrastText: "#fff"
        },
        grey: {
            main: "#ccc",
            light: "#E5E5E5",
            dark: ""
        }
    },
    typography: {
        htmlFontSize: 14,
        fontFamily: "Roboto,sans-serif"
    }
});
