import React from "react";
import {entityToChar} from "../../app/config";

export function ListItemRow(props) {
    return (<div className="results__overview__list__item__row">
        <div className="results__overview__list__item__total">{props.label}</div>
        <div className="results__overview__list__item__price">{entityToChar(props.value)}</div>
    </div>)

}
