/**
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export function Content(props) {

    const {bb, className} = props;

    return <div className={"content" + (bb ? " content--bb" : "") + (className ? " " + className : "")}>
        <div className="container">
            {props.children}
        </div>
    </div>

}
