import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {Identity} from "../../components/Identity";
import AppContainer from "../../components/AppContainer";
import {HeaderDefault, HeaderReturn} from "../../layout/Header";
import {useNavigate, useParams} from "react-router-dom";
import {PAGE_TITLE_BASE} from "../../app/config";
import API, {API_BASE, deleteSupplyImages, refreshSupplies, sortSupplyImages} from "../../API";
import Loader from "../../components/Loader";
import {Alerts} from "../../features/alerts/Alerts";
import {Content} from "../../components/Content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import ImageGrid from "../../components/Image/Grid";
import {Camera} from "../../components/Camera";
import {addAlert, clearAlertsByIdentifier} from "../../features/alerts/alertsSlice";
import {useDispatch, useSelector} from "react-redux";
import {arrayMoveImmutable} from "array-move";
import {editListItem, selectList, selectLists} from "../../features/list/listSlice";

let buttonTypeHandler;
let headerActionState;

function HeaderAction({handleReturn, saveHandler}) {

    const [buttonType, setButtonType] = useState("FINISH");
    const [isSaving, setIsSaving] = useState(false);

    const navigate = useNavigate();

    let headerAction = "";

    buttonTypeHandler = setButtonType;
    headerActionState = isSaving;

    const handleSave = async (ev) => {
        setIsSaving(true);
        if (saveHandler) {
            await saveHandler();
        }
        if (handleReturn) {
            handleReturn()
        }
    };

    if (buttonType) {
        switch (buttonType.toUpperCase()) {
            case 'SAVE':
                if (!isSaving) {
                    headerAction = <button className={"button button--primary d-block p-1 h-auto"}
                                           onClick={handleSave}>Opslaan</button>;
                } else {
                    headerAction = "Opslaan...";
                }
                break;
            case 'FINISH':
                headerAction = <button className={"button bg-secondary text-white d-block p-1 h-auto"}
                                       onClick={() => navigate("/eigen-aanbod")}>Afronden</button>;
                break;
        }
    }

    return headerAction;

}

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export function SupplyGallery(props) {

    let {supplyId} = useParams();
    const sliderRef = useRef();
    let navigate = useNavigate();

    useSelector((state) => selectLists(state));

    const [isLoading, setIsLoading] = useState(true),
        [data, setData] = useState(null),
        [imagesDeleted, setImagesDeleted] = useState([]),
        dispatch = useDispatch(),
        storedList = useSelector((state) => selectList(state, "IDENTITY"));

    const fetchData = useCallback(async () => {
        await API.fetchSupply(supplyId)
            .then((res) => {
                if (res && res.data.status !== 'OK') {
                    throw Error("Er is een fout opgetreden");
                }

                setData(res.data);

            });

    }, [supplyId])

    useEffect(() => {
        setIsLoading(true);
        document.title = "Foto's bewerken" + PAGE_TITLE_BASE;
        if (!data) {
            fetchData().then(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }, [fetchData, data])

    if (isLoading === true) {
        return (
            <Fragment>

                <Identity/>

                <AppContainer>

                    <HeaderDefault/>

                    <div className={"content"}>
                        <div className="container">
                            <Loader/>
                        </div>
                    </div>
                </AppContainer>

            </Fragment>
        )
    }

    const {plant, images} = data;

    /**
     *
     * @param imageCount
     */
    const updateList = imageCount => {
        if (storedList) {
            dispatch(editListItem({
                identifier: "IDENTITY",
                itemId: storedList.listItemIndex,
                value: {
                    hasPhoto: imageCount
                }
            }));
        }
    }

    /**
     *
     * @type {{onDelete: gridHandlers.onDelete, onSort: gridHandlers.onSort}}
     */
    const gridHandlers = {
        onSortEnd: ({oldIndex, newIndex}) => {
            setData({
                ...data,
                images: arrayMoveImmutable(data.images, oldIndex, newIndex)
            })

            buttonTypeHandler("SAVE");

        },
        onDelete: (index) => {

            setImagesDeleted([
                ...imagesDeleted,
                data.images[index].guid
            ])

            setData({
                ...data,
                images: data.images.filter((_, i) => i !== index)
            });

            buttonTypeHandler("SAVE");

        }
    }

    // const storedList = selectList(this.props, "IDENTITY");

    const handleSave = () => {

        buttonTypeHandler("SAVE");

        dispatch(addAlert({
            identifier: "galleryAlerts",
            type: "success",
            icon: faTriangleExclamation,
            message: "Uw verzoek tot opslaan/verwijderen van een foto is ontvangen. Dit proces loopt op de achtergrond en kan ca. 2 minuten duren."
        }));

        const update = async () => {

            const guids = data.images.map(image => image.guid);

            if (imagesDeleted.length > 0) {
                await deleteSupplyImages({
                    supplyId: supplyId,
                    guids: imagesDeleted
                })
            }

            await sortSupplyImages({
                supplyId: supplyId,
                guids: guids
            }).then(() => refreshSupplies("IDENTITY", [supplyId]).then(() => updateList(guids.length)))

        }

        update().then((r) => {
            // dispatch(clearAlertsByIdentifier("galleryAlerts"))
        });

    }

    const cameraHandlers = {
        upload: async (file) => {
            await API.uploadSupplyPhoto(supplyId, file)
                .then((res) => {

                    setData({
                        ...data,
                        actualizationDate: res.data.actualizationDate,
                        images: [...data.images, {
                            sort: data.images.length + 1,
                            uri: API_BASE + "/fileimage/guid/" + res.data.guid,
                            guid: res.data.guid
                        }]
                    })

                    dispatch(addAlert({
                        identifier: "uploadAlerts",
                        type: "success",
                        message: "SUPPLY-IMAGE-UPLOAD-SUCCESS"
                    }));

                    refreshSupplies("IDENTITY", [supplyId]);
                    updateList(data.images.length + 1)

                }).catch((err) => {

                    dispatch(addAlert({
                        identifier: "uploadAlerts",
                        type: "danger",
                        message: "SUPPLY-IMAGE-UPLOAD-FAILED"
                    }));

                });
        },
        postUpload: () => true
    };

    const handleReturnArrow = () => {
        let returnLink = props.prevLink;

        if (props.prevLink === `/eigen-aanbod/${supplyId}/gallerij`) { // self
            returnLink = `/eigen-aanbod/${supplyId}/snel-bewerken`;
        }

        if (props.prevLink === "/eigen-aanbod/toevoegen") {
            returnLink = `/eigen-aanbod/${supplyId}/bewerken`;
        }

        navigate(returnLink);
    }

    const handleReturn = () => {

        let returnLink = "/eigen-aanbod";

        if (props.prevLink === `/eigen-aanbod/${supplyId}/snel-bewerken`) {
            returnLink = props.prevLink;
        }

        navigate(returnLink);

    };

    return (
        <Fragment>

            <Identity/>

            <AppContainer>

                <HeaderReturn action={<HeaderAction handleReturn={handleReturn} saveHandler={handleSave}/>}
                              handleOnClick={handleReturnArrow}/>

                <Alerts identifier={"uploadAlerts"}/>

                <Content bb={false}>
                    <div className="results__overview__list__item__title">{plant.plantName}</div>
                </Content>

                <Content>

                    <div className="gallery d-flex">

                        <div className="gallery__images">
                            <div className="gallery__images__container">
                                <ImageGrid slides={images}
                                           addHandler={<Camera handlers={cameraHandlers}/>}
                                           gridHandlers={gridHandlers}
                                           thumbnailSize={175}
                                           sliderRef={sliderRef}
                                />
                            </div>
                        </div>
                    </div>
                </Content>

                <Content className={""}>
                    <p className={"mb-0"}>
                        <span className={"me-1"}><FontAwesomeIcon icon={faInfoCircle}/></span>
                        Houd je vinger op de foto en sleep deze om de volgorde te wijzigen. Je wijzigingen worden pas
                        opgeslagen nadat je op opslaan klikt.
                    </p>
                </Content>

            </AppContainer>
        </Fragment>
    );

}
