import React from "react";
import {selectWord} from "../../../features/translate/translateSlice";
import {useSelector} from "react-redux";

export function FormElementSubmit(props) {

    let defaultSubmitLabel = useSelector(state => selectWord(state, "BUTTON-SEND")) ?? "";

    let {buttonLabel, ...buttonProps} = props;

    if (!buttonLabel) {
        buttonLabel = defaultSubmitLabel;
    }

    return <div className={"form__element"}>
        {React.createElement("button", {
            className: "button button--primary button--readmore",
            type: "submit",
            ...buttonProps,
        }, buttonLabel)}
    </div>

}
