import React from "react";
import {selectWord} from "../features/translate/translateSlice";
import {useSelector} from "react-redux";
import {entityToChar} from "../app/config";


/**
 *
 * @param tag
 * @param paragraph
 * @return {*}
 * @constructor
 */
export function Word({tag, paragraph = false}) {

    const word = useSelector((state) => selectWord(state, tag));

    /**
     *
     * @param string
     * @return {*}
     */
    const wordToParagraph = (string) => {
        if (typeof string !== 'string' || !string instanceof String) return string;

        return string.split('\r\n\r\n').map((item, index) => {
            return React.createElement("p", {key: index}, item.split('\r\n').map((word) => {
                return [entityToChar(word), <br/>]
            }))
        });
    }

    let res = word;

    if (paragraph === true) {
        res = wordToParagraph(word);
    }

    return res || tag;

}
