import React from "react";
import {useSwipeable} from "react-swipeable";
import {ListItemAction} from "./ListItemAction";

function ListItem(props) {

    const {left, right} = props.actions;

    let postSwipeLeft = () => true,
        postSwipeRight = () => true;

    if (props.actions.postSwipeLeft) {
        postSwipeLeft = props.actions.postSwipeLeft;
    }
    if (props.actions.postSwipeRight) {
        postSwipeRight = props.actions.postSwipeRight;
    }

    const resetAllSwept = () => {
        document.querySelectorAll(".results__overview__list__item__inner--moveright").forEach((elm) => {
            elm.classList.remove("results__overview__list__item__inner--moveright");
        });
        document.querySelectorAll(".results__overview__list__item__inner--moveleft").forEach((elm) => {
            elm.classList.remove("results__overview__list__item__inner--moveleft");
        });
        document.querySelectorAll(".notification__actions--moveright").forEach((elm) => {
            elm.classList.remove("notification__actions--moveright");
        });
        document.querySelectorAll(".notification__actions--moveleft").forEach((elm) => {
            elm.classList.remove("notification__actions--moveleft");
        });
    }

    const {actions, ...wrapProps} = props;
    const handlers = useSwipeable({
        trackMouse: false,
        trackTouch: true,
        onSwipedLeft: ({event}) => {

            const {target} = event;
            const parent = target.closest(".results__overview__list__item");

            if (!parent) {
                return false;
            }

            const inner = parent.querySelector(".results__overview__list__item__inner");
            const actions = parent.querySelector(".notification__actions");

            if (inner.classList.contains("results__overview__list__item__inner--moveright")) {
                inner.classList.remove("results__overview__list__item__inner--moveright");
                actions.classList.remove("notification__actions--moveright");
            } else {

                if (right.length < 1) {
                    return false;
                }

                resetAllSwept();

                if (!props.actions.disableRightActions) {
                    inner.classList.add("results__overview__list__item__inner--moveleft");
                    actions.classList.add("notification__actions--moveleft");

                }

            }

            postSwipeLeft();

        },
        onSwipedRight: ({event}) => {

            const {target} = event;
            const parent = target.closest(".results__overview__list__item");

            if (!parent) {
                return false;
            }

            const inner = parent.querySelector(".results__overview__list__item__inner");
            const actions = parent.querySelector(".notification__actions");

            if (inner.classList.contains("results__overview__list__item__inner--moveleft")) {
                inner.classList.remove("results__overview__list__item__inner--moveleft");
                actions.classList.remove("notification__actions--moveleft");
            } else {

                if (left.length < 1) {
                    return false;
                }

                resetAllSwept();

                inner.classList.add("results__overview__list__item__inner--moveright");
                actions.classList.add("notification__actions--moveright");
            }

            postSwipeRight();

        }
    });

    // if right actions are disabled for whatever reason, show a small vertical line on the left
    let border;

    if (props.actions.disableRightActions) {
        border = <div className={"bg-blue position-absolute"} style={{
            width: "4px",
            left: "0",
            top: "0",
            height: "100%"
        }}/>
    }

    return (
        <div
            className="results__overview__list__item position-relative results__overview__list__item--alt h-100" {...wrapProps}>

            {border}

            <div className="container">

                <div className="results__overview__list__item__wrapper position-relative">
                    <div className="results__overview__list__item__inner px-0" {...handlers}>
                        {props.children}
                    </div>

                    <div className="notification__actions">
                        <div className="notification__actions__left">
                            {left.map((action, index) => <React.Fragment key={index}>{action}</React.Fragment>)}
                        </div>
                        <div className="notification__actions__right">
                            {right.map((action, index) => <ListItemAction key={index} {...action}/>)}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ListItem;
