import React, {Fragment} from "react";
import AppContainer from "../components/AppContainer";
import {HeaderDefault} from "../layout/Header";
import {NavLink} from "react-router-dom";
import {Content} from "../components/Content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {PAGE_TITLE_BASE} from "../app/config";
import {useEffect} from "react";
import {Identity} from "../components/Identity";
import {APP_DATE, APP_VERSION} from "../API";
import {Word} from "../components/Word";
import {faCalendarAlt} from "@fortawesome/free-regular-svg-icons";

export function AppSettings() {

    useEffect(() => {
        document.title = "App info" + PAGE_TITLE_BASE;
    }, [])

    return (
        <Fragment>

            <Identity/>

            <AppContainer>

                <HeaderDefault/>

                <Content bb={true}>

                    <h1 className={"text-primary"}>
                        <Word tag={"ROUTE-APPSETTINGS-TITLE"} />
                    </h1>

                    <p>
                        <strong><Word tag={"ROUTE-APPSETTINGS-LABEL-APPNAME"}/></strong>&nbsp;<Word
                        tag={"ROUTE-APPSETTINGS-APPNAME"}/><br/>
                        <strong><Word tag={"ROUTE-APPSETTINGS-LABEL-VERSION"}/></strong>&nbsp;{APP_VERSION}<br/>
                        <strong>Datum:</strong>&nbsp;<FontAwesomeIcon icon={faCalendarAlt} />&nbsp;{APP_DATE.toLocaleDateString('nl-NL')}
                    </p>
                </Content>

                <Content bb={true}>

                    <div>
                        <Word tag={"ROUTE-APPSETTINGS-DESCR"} paragraph={true}/>
                    </div>

                    <button onClick={() => window.open('tel:+31172235020')} className={"button button--primary"}>
                        <FontAwesomeIcon icon={faPhone}/>
                    </button>
                    <button onClick={() => window.open('mailto:info@varb.nl')}
                            className={"button button--primary ms-2"}>
                        <FontAwesomeIcon icon={faEnvelope}/>
                    </button>
                </Content>

                <Content>
                    <NavLink to={"/auth/logout"} className={"button button--primary button--readmore"}>
                        <Word tag={"BUTTON-LOGOUT"}/>
                    </NavLink>
                </Content>

                <Content className={"mt-auto"}>
                    <div className="text-muted text-center">
                        <p><small>Ontwikkeld door: Faceworks B.V.</small></p>
                    </div>
                </Content>

            </AppContainer>
        </Fragment>
    )

}
