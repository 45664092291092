import React from "react";
import {useFormValidation} from "../FormFromArray";
import {useFormContext} from "react-hook-form";

/**
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export function FormElement(props) {

    const methods = useFormContext(),
        {getFieldState} = methods;

    const fieldState = getFieldState(props.name);

    let {errors} = useFormValidation(fieldState.error);

    return <div className={"form__element"}>
        {props.element}
        {errors}
    </div>

}
