import {Link, useNavigate, useLocation} from "react-router-dom";
import React from "react";
import logo from "../img/assets/varb-logo.svg";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


/**
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export const HeaderDefault = (props) => {

    let border = <div className="border-gradient"/>;
    let disableLink = false;

    if (props.hideBorder === true) {
        border = null;
    }
    if (props.disableLink === true) {
        disableLink = true
    }

    return (

        <header className="d-flex align-items-center">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                    <div className="logo">
                        {(disableLink ? "" : <Link to={"/eigen-aanbod"}>
                                <img src={logo} alt=""/>
                            </Link>
                        )}
                    </div>

                    {props.action && (<div className="header-top-action ms-auto">{props.action}</div>)}

                </div>
            </div>

            {border}

        </header>

    )
};

/**
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export const HeaderReturn = (props) => {

    const navigate = useNavigate();
    const location = useLocation();

    const handleOnClick = () => {
        navigate(-1, {
            props: location.props
        });
    }

    let handleClick = props.handleOnClick ?? handleOnClick;

    if (typeof props.handleOnClick === "string") {
        handleClick = () => navigate(props.handleOnClick);
    }

    return (
        <header className="d-flex align-items-center bg-light border-bottom">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="header-top-label text-primary">
                        <span className={"pe-3"} onClick={handleClick}
                              style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                        </span>
                        {props.headerTitle && (<span className="fw-bolder">{props.headerTitle}</span>)}
                    </div>
                    {props.action && (<div className="header-top-action">{props.action}</div>)}
                </div>
            </div>
        </header>
    );

}
