import React, {Fragment} from "react";

const words = {

    /*
     * General tags
     */
    "PWA-BUTTON-SUBMIT": "Toepassen",
    "PWA-BUTTON-SEND": "Verzenden",
    "PWA-BUTTON-SAVE": "Opslaan",
    "PWA-BUTTON-CANCEL": "Annuleer",
    "PWA-BUTTON-SELECT": "Selecteren",
    "PWA-ALERT-SESSION-LOGIN-EXPIRED": "Uw sessie is verlopen. Log hieronder opnieuw in.",
    "PWA-PAGE-NOT-FOUND": "Pagina niet gevonden.",
    "PWA-ERROR-OCCURRED": "Er ging iets mis op deze pagina",
    "PWA-ERROR-OCCURRED-RETURN-MESSAGE": "Er ging iets mis op deze pagina",
    "PWA-ERROR-OCCURRED-RETURN-BUTTON-TEXT": "Er ging iets mis op deze pagina",
    "PWA-FORM-ERROR-GENERALMESSAGE": "Controleer de velden",
    "PWA-LABEL-YES": "Ja",
    "PWA-LABEL-NO": "Nee",
    "PWA-LOADING-TEXT": "Gegevens ophalen...",
    "PWA-FORM-ERROR-FIELD-RULE-REQUIRED": "Dit veld is verplicht",
    "PWA-FORM-ERROR-FIELD-RULE-EMAIL-INVALID": "Onjuiste e-mail",
    "PWA-FORM-ERROR-FIELD-RULE-NOT-UNIQUE": "Velden mogen niet gelijk zijn",
    "PWA-FORM-ERROR-FIELD-RULE-NUMBER-INVALID": "Onjuist getal",
    "PWA-FORM-ERROR-FIELD-RULE-FEATURELFT1-AND-FEATURELFT2-CANNOT-BE-EQUAL": "LFT1 en LFT2 mogen niet dezelfde waarden hebben",

    /*
     * Route titles
     */
    "PWA-ROUTE-SUPPLY-TITLE": "Eigen aanbod",
    "PWA-ROUTE-SEARCH-TITLE": "Aanbod zoeken",
    "PWA-ROUTE-SEARCHRESULTS-TITLE": "Zoekresultaten",
    "PWA-ROUTE-APPSETTINGS-TITLE": "App info",
    "PWA-ROUTE-RETURN": "Terug",

    /*
     * Route: AuthLogin
     */
    "PWA-HEADER-LOGIN-TITLE": "Inloggen",
    "PWA-BUTTON-AUTH-FORGOT": "Wachtwoord vergeten",
    "PWA-LABEL-LOGIN-DESCR": "Let op! Varb kent per gebruiker één algemeen wachtwoord voor alle toepassingen.",
    "PWA-LABEL-LOGIN-USERNAME": "Je gebruikersnaam",
    "PWA-PLACEHOLDER-LOGIN-USERNAME": "Gebruikersnaam / e-mail",
    "PWA-LABEL-LOGIN-PASSWORD": "Je Varb wachtwoord",
    "PWA-PLACEHOLDER-LOGIN-PASSWORD": "Wachtwoord",
    "PWA-LABEL-LOGIN-REMEMBER": "Aangemeld blijven",
    "PWA-BUTTON-LOGIN": "Inloggen",
    "PWA-BUTTON-LOGOUT": "Uitloggen",

    /*
     * Route: AuthForgot
     */
    "PWA-HEADER-FORGOT-TITLE": "Wachtwoord vergeten",
    "PWA-LABEL-AUTH-FORGOT-EMAIL": "Je e-mailadres",
    "PWA-PLACEHOLDER-AUTH-FORGOT-EMAIL": "email@email.nl",
    "PWA-AUTH-FORGOT-DESCR": "Vul je e-mailadres in en je ontvangt een e-mail met een link om je wachtwoord te resetten.\n\n Let op! Hiermee verander je het wachtwoord voor al je Varb toepassingen.",
    "PWA-AUTH-FORGOT-CANCEL": "Annuleren",

    /*
     * Route: Supply
     */
    "PWA-SUPPLY-LIST-ACTION-REFRESH": "Verversen",
    "PWA-SUPPLY-LIST-ACTION-CAMERA": "Camera",
    "PWA-SUPPLY-LIST-ACTION-EDIT": "Bewerken",
    "PWA-SUPPLY-LIST-LABEL": "Etiket",
    "PWA-SUPPLY-LIST-ON-REQUEST": "Op aanvraag",

    /*
     * Route: SupplyEdit
     */
    "PWA-SUPPLY-EDIT-ALERT-CANCELLED": "Uw wijzigingen zijn niet opgeslagen.",
    "PWA-SUPPLY-EDIT-IMAGES": "Foto's",
    "PWA-SUPPLY-IMAGE-UPLOAD-SUCCESS": "Foto succesvol geupload",
    "PWA-SUPPLY-IMAGE-UPLOAD-FAILED": "Foto uploaden mislukt",
    "PWA-SUPPLY-IMAGE-REMOVE-SUCCESS": "Foto succesvol verwijderd",
    "PWA-SUPPLY-IMAGE-SORT-SUCCESS": "Sortering succesvol bijgewerkt",
    "PWA-LABEL-SUPPLY-EDIT-PLANTNUMBER": "Plannummer",
    "PWA-LABEL-SUPPLY-EDIT-PLANTCODE": "Plantcode",
    "PWA-LABEL-SUPPLY-EDIT-FULLNAME": "Volledige naam",
    "PWA-LABEL-SUPPLY-ACTION-EDIT-SUCCESSFUL": "Aanbod succesvol bijgewerkt",
    "PWA-LABEL-SUPPLY-ACTION-ADDED-SUCCESSFUL": "Aanbod succesvol toegevoegd",
    "PWA-SUPPLY-GALLERY-EDIT-SUCCESSFUL": "Wijzigingen succesvol opgeslagen",
    "SUPPLY-EDIT-ADDED-SUCCESSFUL": "Aanbod succesvol toegevoegd",
    "PWA-SUPPLY-EDIT-ACTION-HANDLE-PHOTOS-HELPER-TEXT": "Na het opslaan kun je foto's toevoegen",


    /*
     * Route: Supply Details
     */
    "PWA-ROUTE-SUPPLY-DETAILS-AMOUNT": "Aantal",
    "PWA-ROUTE-SUPPLY-DETAILS-DISPLAYPRICE": "Prijs per stuk af kwekerij",
    "PWA-ROUTE-SUPPLY-DETAILS-AMOUNTPURCHASEMIN": "Minimale afname",
    "PWA-ROUTE-SUPPLY-DETAILS-DISPLAYFEATURECOLLAPPEARANCE": "Verschijningsvorm",
    "PWA-ROUTE-SUPPLY-DETAILS-DISPLAYQUALITYSIZE": "Maat",
    "PWA-ROUTE-SUPPLY-DETAILS-DISPLAYQUALITYPKW": "PKW",
    "PWA-ROUTE-SUPPLY-DETAILS-DISPLAYQUALITYLFT": "LFT 1",
    "PWA-ROUTE-SUPPLY-DETAILS-DISPLAYQUALITYLFT2": "LFT 2",
    "PWA-ROUTE-SUPPLY-DETAILS-DISPLAYQUALITYVISUAL": "Visueel",
    "PWA-ROUTE-SUPPLY-DETAILS-DISPLAYREQCERTIFICATES": "Certificering product",
    "PWA-ROUTE-SUPPLY-DETAILS-LABEL-COMMENT": "Opmerking",

    "PWA-ROUTE-SUPPLY-DETAILS-LABEL": "Etiket",
    "PWA-ROUTE-SUPPLY-DETAILS-DISPLAYDELIVERABLEDATETYPE": "Levermoment",
    "PWA-ROUTE-SUPPLY-DETAILS-DISPLAYDELIVERYDURATION": "Levertijd",
    "PWA-ROUTE-SUPPLY-DETAILS-PLANTCODE": "Plantcode",
    "PWA-ROUTE-SUPPLY-DETAILS-REGISTRATIONSTATUS": "Registratiestatus",
    "PWA-ROUTE-SUPPLY-DETAILS-ACTUALIZATIONDATE": "Geactualiseerd op",
    "PWA-ROUTE-SUPPLY-DETAILS-EXPIREDATE": "Verloopt op",

    /*
     * Route: Search
     */
    "PWA-SEARCH-CONTENT": "Zoek in aanbod",

    /*
     * Route: App Settings
     */
    "PWA-ROUTE-APPSETTINGS-DESCR": <Fragment>
        <p>Varb, het platform voor buitengroen wordt geëxploiteerd door:</p>

        <p className={"ps-2"}>
            <strong>Varb B.V.</strong><br/>
            Engelandlaan 70-72<br/>
            2391 PN Hazerswoude-Dorp<br/>
            Nederland<br/>
            <a rel={"noreferrer"} href={"https://www.varb.nl/"} target={"_blank"}
               className={"text-primary"}><u>www.varb.nl</u></a>
        </p>

        <p>De Varb app is constant in ontwikkeling. Heb je wensen of tips? Laat het ons weten!</p>
    </Fragment>,

    "PWA-ROUTE-APPSETTINGS-LABEL-APPNAME": "Naam",
    "PWA-ROUTE-APPSETTINGS-APPNAME": "Varb app",
    "PWA-ROUTE-APPSETTINGS-LABEL-VERSION": "Versie",


    /*
     * Module: Supply filters
     */
    "PWA-SUPPLYFILTERS-TITLE": "Filter en sorteer",
    "PWA-SUPPLYFILTERS-SHOWZEROQUANTITY": "0-aanbod tonen",
    "PWA-SUPPLYFILTERS-DIRECTDELIVERY": "Per direct leverbaar",
    "PWA-SUPPLYFILTERS-FILTER": "Filter",
    "PWA-SUPPLYFILTERS-SORT-RELEVANCE": "Relevantie",
    "PWA-SUPPLYFILTERS-SORT-DATE": "Datum",
    "PWA-SUPPLYFILTERS-SORT-PRICE-ASC": "Prijs oplopend",
    "PWA-SUPPLYFILTERS-SORT-PRICE-DESC": "Prijs aflopend",
    "PWA-SUPPLYFILTERS-SORT-QUANTITY-ASC": "Aantal oplopend",
    "PWA-SUPPLYFILTERS-SORT-QUANTITY-DESC": "Aantal aflopend",

    /*
     * Module: Autocomplete tags
     */
    "PWA-AUTOCOMPLETE-PLACEHOLER": "VB: Acer palmatum 60-80 C5",
    "PWA-LABEL-ACPREFILL-PREFIX-ORG": "Bedrijf",

    /*
     * Module: Datepicker tags
     */
    "PWA-DATEPICKER-SELECT-DATE": "Selecteer een datum",
    "PWA-DATEPICKER-TODAY": "Vandaag",

    /*
     * Layout: Footer links
     */
    "PWA-ROUTES-LINK-SUPPLY": "Eigen aanbod",
    "PWA-ROUTES-LINK-SEARCH": "Zoeken",
    "PWA-ROUTES-LINK-APPSETTINGS": "Meer",

}

export default words;
