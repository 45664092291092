import React from "react";

/**
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export function Form(props) {

    const {children, ...elemProps} = props;

    return <div className="form">
        <form {...elemProps}>
            {children}
        </form>
    </div>
}
