import axios from "axios";
import store from "./app/store";
import {selectWords} from "./features/translate/translateSlice";
import {logOut} from "./features/user/userSlice";
import {resizeImage} from "./app/config";
import {addAlert} from "./features/alerts/alertsSlice";

export const API_BASE = "https://" + process.env.REACT_APP_API_BASE;
export const API_URL = "https://" + process.env.REACT_APP_API_SERVICE + "/Rest/Pwa";
export const IMAGE_BASE = API_BASE + "/fileimage/type/preview-existing";
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_DATE = new Date(2023, 10, 8);

const API = {

    /**
     *
     * @return {{headers: {"Content-Type": string}}}
     */
    getHeaders: () => {
        let headers = {
            'Content-Type': 'application/json',
        }

        const {user} = store.getState();

        if (user && user.token) {
            headers = {...headers, 'X-Auth-Token': user.token}
        }


        return {headers: headers};

    },

    /**
     *
     * @param formData
     * @returns {Promise<AxiosResponse<any>>}
     */
    userLogin: formData => axios.post(`${API_URL}/auth/login`, formData, API.getHeaders())
        .catch(API.handleNetworkError),

    /**
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    userLogout: () => axios.get(`${API_URL}/auth/logout`, API.getHeaders())
        .catch(API.handleNetworkError),

    /**
     *
     * @returns {Promise<AxiosResponse<any>>}
     * @param email
     */
    userForgot: email => axios.post(`${API_URL}/auth/forgot`, {emailaddress: email}, API.getHeaders())
        .catch(API.handleNetworkError),

    /**
     *
     * @param organisationId
     */
    fetchOrganisation: (organisationId) => {
    },

    /**
     *
     * @param formData
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchSupplies: (formData) => axios.post(`${API_URL}/supplies`, formData, API.getHeaders())
        .catch(API.handleNetworkError),

    /**
     *
     * @param supplyId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchSupply: supplyId => axios.get(`${API_URL}/supply/${supplyId}`, API.getHeaders())
        .catch(API.handleNetworkError),

    fetchAutocompleteOptions: (query, context) => (
        axios.post(`${API_URL}/autocomplete`, {
            "searchType": "terms",
            "terms": query,
            "context": context,
            "index": query.length
        }, API.getHeaders()).catch(API.handleNetworkError)
    ),

    /**
     *
     * @param supplyId
     * @return {Promise<AxiosResponse<any>>}
     */
    fetchImages: supplyId => axios.get(`${API_URL}/supply/${supplyId}/images`, API.getHeaders())
        .catch(API.handleNetworkError),


    /**
     *
     * @return {Promise<AxiosResponse<any>>}
     */
    fetchSupplyForm: () => axios.get(`${API_URL}/form/supplyfields`, API.getHeaders())
        .catch(API.handleNetworkError),

    /**
     *
     * @param supplyId
     * @param type
     * @return {Promise<AxiosResponse<any>>}
     */
    fetchSearchRequestForm: (supplyId, type) => axios.get(`${API_URL}/form/informationFields/${supplyId}/${type}`, API.getHeaders())
        .catch(API.handleNetworkError),

    /**
     *
     * @return {Promise<AxiosResponse<any>>}
     */
    fetchIdentityStatus: () => axios.get(`${API_URL}/ping`, API.getHeaders())
        .catch(API.handleNetworkError),

    /**
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchWords: () => {

        const words = selectWords(store.getState());
        const tags = Object.keys(words);

        return axios.post(`${API_URL}/translate`, {tags: tags}, API.getHeaders())
            .catch(API.handleNetworkError);
    },

    /**
     *
     * @param context
     * @param supplyIds
     * @return {Promise<AxiosResponse<any>>}
     */
    refreshSupplies: (context, supplyIds = []) => (
        axios.post(`${API_URL}/supplies/refresh`, {
            context: context,
            supplyIds: supplyIds
        }, API.getHeaders()).catch(API.handleNetworkError)
    ),

    /**
     *
     * @param supplyId
     * @param file
     * @return {Promise<AxiosResponse<any>>}
     */
    uploadSupplyPhoto: (supplyId, file) => {

        let type = "image/jpeg";

        if (file.type === "image/png") {
            type = "image/png";
        }

        return resizeImage({file}).then((data) => {
            const base64 = data.image.toDataURL(type).split(',')[1];
            const postData = {
                filename: file.name,
                mimeType: type,
                content: base64,
            }

            return axios.post(`${API_URL}/supply/${supplyId}/image`, postData, API.getHeaders()).catch(API.handleNetworkError)
        });

    },

    /**
     *
     * @param supplyId
     * @param data
     * @return {Promise<AxiosResponse<any>>}
     */
    editSupply: (supplyId, data) => axios.post(`${API_URL}/supply/${supplyId}`, data, API.getHeaders())
        .catch(API.handleNetworkError),

    editSupplyAdvanced: (supplyId, data) => axios.post(`${API_URL}/supply/${supplyId}/extended`, {data: data}, API.getHeaders())
        .catch(API.handleNetworkError),

    /**
     *
     * @param supplyId
     * @param type
     * @param data
     * @return {Promise<AxiosResponse<any>>}
     */
    sendSearchRequestForm: (supplyId, type, data) => {
        return axios.post(`${API_URL}/supply/${supplyId}/information-request/${type}`, data, API.getHeaders())
            .catch(API.handleNetworkError)
    },

    /**
     *
     * @param formData
     * @return {Promise<AxiosResponse<any>>}
     */
    addSupply: (formData) => {
        return axios.post(`${API_URL}/supply`, {data: formData}, API.getHeaders())
            .catch(API.handleNetworkError)
    },

    /**
     *
     * @param res
     */
    handleResponse: (res) => {
        if (res.data !== "OK") {
            API.callback();
        }
    },

    /**
     * If an error occurred, logout
     * @param err
     */
    callback: (err) => {
        const dispatch = store.dispatch;
        dispatch(logOut());
    },

    /**
     *
     * @param err
     */
    handleNetworkError: (err) => {
        const dispatch = store.dispatch;
        dispatch(addAlert({
            identifier: "appErrors",
            type: "danger",
            message: "Geen netwerk gevonden"
        }));
    },

    /**
     *
     * @param supplyId
     * @param guids
     * @return {Promise<AxiosResponse<any>>}
     */
    deleteSupplyImages: ({supplyId, guids}) => {

        const config = {
            ...API.getHeaders(),
            data: {guids: guids ?? []},
        }

        return axios.delete(`${API_URL}/supply/${supplyId}/images`, config).catch(API.handleNetworkError);
    },


    /**
     *
     * @param supplyId
     * @param guids
     * @return {Promise<AxiosResponse<any>>}
     */

    sortSupplyImages: ({supplyId, guids}) => {
        return axios.post(
            `${API_URL}/supply/${supplyId}/images/sort`,
            {guids: guids ?? []},
            API.getHeaders()
        ).catch(API.handleNetworkError);
    },

    fetchAppearanceLists: (appearanceId) => axios.get(`${API_URL}/lists/appearance/${appearanceId}`, API.getHeaders())
        .catch(API.handleNetworkError),

    fetchFormDataBySupply: supplyId => axios.get(`${API_URL}/supply/${supplyId}/datasource`, API.getHeaders())
        .catch(API.handleNetworkError),


}

export const {
    fetchWords,
    refreshSupplies,
    fetchAutocompleteOptions,
    deleteSupplyImages,
    sortSupplyImages,
    fetchSupplyForm,
    fetchAppearanceLists,
    addSupply,
    fetchFormDataBySupply,
    editSupplyAdvanced
} = API;

export default API;
