import React, {Fragment} from "react";
import {connect} from "react-redux";
import ListItem from "../../components/List/ListItem";
import Autocomplete from "../../features/autocomplete/Autocomplete";
import {fetchSupplyImages, PAGE_TITLE_BASE, withNavigate} from "../../app/config";
import AppContainer from "../../components/AppContainer";
import {HeaderReturn} from "../../layout/Header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEllipsisH, faEnvelope, faPhone, faUserCheck, faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import {IMAGE_BASE} from "../../API";
import {SuppliesList} from "../../components/Supplies/SuppliesList";
import {
    selectSelected,
    setSelected
} from "../../features/autocomplete/autocompleteSlice";
import {SupplyFilters} from "../../features/supplyFilters/SupplyFilters";
import {setActive} from "../../features/supplyFilters/supplyFiltersSlice";
import {faImage, faImages} from "@fortawesome/free-regular-svg-icons";
import {ListItemRow} from "../../components/List/ListItemRow";
import {SubmenuOption} from "../../components/Submenu/SubmenuOption";
import {setStatus} from "../../features/popup/popupSlice";
import {Content} from "../../components/Content";
import {Identity} from "../../components/Identity";
import {Submenu} from "../../components/Submenu";
import {setList} from "../../features/list/listSlice";

/**
 * SearchResults class
 */
class SearchResults extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            supplyId: null,
            scrollLast: 0,
            submenu: {
                active: false,
                actions: null,
            },
            formData: {
                searchQuery: "",
                context: "NONE",
                terms: "",
                index: 0,
                page: 1
            }


        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

        this.autocompleteRef = React.createRef();

    }

    /**
     *
     * @returns {Promise<void>}
     */
    componentDidMount = () => {

        const selected = selectSelected(this.props, this.state.formData.context);

        document.title = "Zoekresultaten" + PAGE_TITLE_BASE

        if (selected && selected[0]) {

            this.setState({
                formData: {
                    ...this.state.formData,
                    terms: selected[0].repeatSearchQuery
                }
            });
        }

        this.setState({
            isLoaded: true,
        });

    }

    /**
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.formData.terms !== ""
            && selectSelected(this.props, this.state.formData.context) === false) {

            this.setState({
                formData: {
                    ...this.state.formData,
                    terms: "",
                    index: 0
                }
            });
        }

        sessionStorage.removeItem("searchResultsList");
    }

    /**
     *
     * @param item
     * @param index
     * @param onClickCallback
     * @return {JSX.Element}
     */
    listItem = (item, index, onClickCallback) => {

        let actionsLeft = [],
            actionsRight = [
                {
                    identifier: "more",
                    icon: <FontAwesomeIcon icon={faEllipsisH}/>,
                    label: "Meer",
                    extraClass: "bg-grey-darks",
                    onClick: () => {

                        const {supplyId} = item;

                        const getLink = requestType => {
                            return `/zoeken/resultaten/${supplyId}/aanvragen/${requestType.toLowerCase()}`
                        }

                        const submenuOptions = <Fragment>
                            <SubmenuOption to={"#"} onClick={() => window.open('tel:' + item.ownerNumberPhone1)}>
                                <span className={"me-1"}><FontAwesomeIcon icon={faPhone}/></span>
                                Bellen
                            </SubmenuOption>
                            <SubmenuOption to={getLink("BUY")}>
                                <span className={"me-1 icon icon-handshake"}/>
                                Koopbevestiging</SubmenuOption>
                            <SubmenuOption to={getLink("INFO")}>
                                <span className={"me-1 icon icon-price-request"}/>
                                Prijsaanvraag</SubmenuOption>

                            {(item.hasPhoto) < 1 ? <SubmenuOption onClick={getLink("IMAGE")}>
                                <span className={"me-1"}><FontAwesomeIcon icon={faImage}
                                                                          fixedWidth={true}/></span>
                                Fotoaanvraag</SubmenuOption> : null}
                            {(item.hasRelation ? null :
                                <SubmenuOption to={getLink("RELATION")}>
                                    <span className={"me-1"}><FontAwesomeIcon icon={faUserPlus}
                                                                              fixedWidth={true}/></span>
                                    Relatieverzoek</SubmenuOption>)}
                        </Fragment>

                        this.props.dispatch(setStatus("visible"));

                        this.setState({
                            submenu: {
                                ...this.state.submenu,
                                actions: submenuOptions
                            }
                        });
                    }
                },
                {
                    identifier: "email",
                    icon: <FontAwesomeIcon icon={faEnvelope}/>,
                    label: "Mail",
                    extraClass: "bg-secondary",
                    onClick: () => window.open('mailto:' + item.ownerEmailAddress)
                },
                {
                    identifier: "phone",
                    icon: <FontAwesomeIcon icon={faPhone} flip={"horizontal"}/>,
                    label: "Bellen",
                    extraClass: "bg-primary",
                    onClick: () => window.open('tel:' + item.ownerNumberPhone1)
                }
            ];

        if (item.thumbnail) {
            actionsLeft = [<img
                src={IMAGE_BASE + "/guid/" + item.thumbnail + "/type/preview-existing/directive/scalearwithin-100x66"}
                decoding={"sync"}
                alt={"Thumbnail " + item.title}
                onClick={() => {
                    this.setState({supplyId: item.supplyId}, () => fetchSupplyImages(this.state.supplyId))
                }}/>
            ];

        }

        let photoIcon = null;

        if (parseInt(item.hasPhoto) > 1) {
            photoIcon = <span className="icon me-1"><FontAwesomeIcon icon={faImages}/></span>;

        } else if (parseInt(item.hasPhoto) === 1) {
            photoIcon = <span className="icon me-1"><FontAwesomeIcon icon={faImage}/></span>;
        }

        const quantityStr = (item.quantity ? parseFloat(item.quantity).toLocaleString("nl-NL") : "");

        return (
            <ListItem key={index}
                      data-supply-id={item.supplyId}
                      actions={{
                          left: actionsLeft,
                          right: actionsRight,
                          disableRightActions: parseInt(item.owned) === 1
                      }}>

                <div onClick={onClickCallback}>

                    <ListItemRow label={<span className="fw-bold">{photoIcon}{item.title}</span>}/>

                    <ListItemRow label={item.displayQuality} value={quantityStr}/>
                    <ListItemRow label={
                        <span className={"owner"}>
                            {(parseInt(item.hasRelation) === 1
                                    ? <span className={"text-blue me-1"}>
                                        <FontAwesomeIcon icon={faUserCheck}/>
                                    </span>
                                    : ""
                            )}
                            {item.ownerName}
                        </span>
                    } value={item.price || "Op aanvraag"}/>

                </div>


            </ListItem>
        );
    }

    /**
     *
     * @return {JSX.Element}
     */
    handleTerms = () => {
        return <ListItem key={"supply-list-no-term"} actions={{left: [], right: []}}>
            <div className="results__overview__list__item__row">
                <div className="results__overview__list__item__text results__overview__list__item__title">
                    Voer een zoekterm in
                </div>
                <div className="results__overview__list__item__text"/>
            </div>
        </ListItem>
    }

    /**
     *
     * @param query
     * @return {boolean}
     */
    handleSelect = (query) => {

        if (!query[0]) {
            return false;
        }

        this.autocompleteRef.current.blur();

        this.props.dispatch(setSelected({
            identifier: this.state.formData.context,
            value: query
        }));

        this.props.dispatch(setList({
            identifier: this.state.formData.context,
            triggerRefresh: true
        }));

        this.setState({
            formData: {
                ...this.state.formData,
                terms: this.autocompleteRef.current.inputNode.value,
                index: this.autocompleteRef.current.inputNode.value.length
            }
        });
    }

    /**
     *
     * @param event
     * @returns {boolean}
     */
    handleSubmit = event => {

        event.preventDefault();

        this.props.dispatch(setActive(false));
        this.props.dispatch(setList({
            identifier: this.state.formData.context,
            triggerRefresh: true
        }));

        const form = event.target;

        if (!form) {
            return false;
        }

        this.setState({
            error: null,
            stopLoading: false,
            formData: {
                ...this.state.formData,
                terms: this.autocompleteRef.current.inputNode.value,
                index: this.autocompleteRef.current.inputNode.value.length,
                page: 1
            }
        });

    }

    render() {

        const {error} = this.state;
        const header = <HeaderReturn handleOnClick={() => {
            this.props.navigate("/zoeken");
        }}/>

        const forwardedRef = this.autocompleteRef;
        const autocompleteHandle = <Autocomplete forwardedRef={forwardedRef}
                                                 context={this.state.formData.context}
                                                 onChange={this.handleSelect}
                                                 handleSubmit={this.handleSubmit}
                                                 onKeyDown={(e) => {

                                                     if (e.keyCode === 13) {
                                                         this.props.dispatch(setSelected({
                                                             identifier: this.state.formData.context,
                                                             value: [{
                                                                 repeatSearchQuery: forwardedRef.current.inputNode.value
                                                             }]
                                                         }));

                                                         this.props.dispatch(setList({
                                                             identifier: this.state.formData.context,
                                                             triggerRefresh: true
                                                         }));


                                                         forwardedRef.current.blur();
                                                         this.handleSubmit(e);
                                                         return true;
                                                     }

                                                     this.setState({
                                                         formData: {
                                                             ...this.state.formData,
                                                             terms: "",
                                                         }
                                                     })

                                                 }}
        />;

        const supplyFilters = <SupplyFilters handleSubmit={this.handleSubmit}
                                             identifier={this.state.formData.context}
                                             autocompleteHandle={autocompleteHandle}/>;

        let content;

        if (error) {
            content = <Content><p>Error: {error.message}</p></Content>;
        } else if (this.state.isLoaded !== false) {
            const {submenu} = this.state;
            content = <SuppliesList
                loadedDefault={true}
                allowEmptyTerms={false}
                allowSelectedFalse={false}
                supplyId={this.state.supplyId}
                handleListItem={this.listItem}
                handleTerms={this.handleTerms}
                formData={this.state.formData}
                minQueryLength={3}
                submenu={<Submenu>{submenu.actions}</Submenu>}
            />
        }

        return (
            <Fragment>
                <Identity/>

                <AppContainer>

                    {header}

                    {supplyFilters}

                    {content}

                </AppContainer>
            </Fragment>
        );

    }

}

const MapStateToProps = (state) => ({
    user: state.user,
    autocomplete: state.autocomplete,
    supplyFilters: state.supplyFilters,
    alerts: state.alerts
});

export default connect(MapStateToProps)(withNavigate(SearchResults));
