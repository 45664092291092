import React, {useEffect} from 'react';
import {Navigate as Redirect} from "react-router-dom";

import {logOut} from "../../features/user/userSlice";
import API from "../../API";
import {useDispatch} from "react-redux";
import {pageViewsTracking} from "../../app/config";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function AuthLogout() {

    const dispatch = useDispatch();

    useEffect(() => {

        const logout = async () => {
            await API.userLogout().then(() => dispatch(logOut()));
        }

        pageViewsTracking();
        logout();

    }, [dispatch])

    return <Redirect to="/"/>;

}

export default AuthLogout;
