import React, {Component, createRef, Fragment, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {PAGE_TITLE_BASE, withNavigate} from "../../app/config";
import AppContainer from "../../components/AppContainer";
import {HeaderReturn} from "../../layout/Header";
import {Form} from "../../components/Form";
import {Content} from "../../components/Content";
import API from "../../API";
import {ListItemRow} from "../../components/List/ListItemRow";
import Carousel from "../../components/Carousel";
import {SupplyDetails} from "../../components/Supply/SupplyDetails";
import {addAlert, clearAlertsByIdentifier} from "../../features/alerts/alertsSlice";
import {Alerts} from "../../features/alerts/Alerts";
import {FormLabel} from "../../components/Form/FormLabel";
import {FormElementInput} from "../../components/Form/Element/FormElementInput";
import {faCamera, faPlus, faRedoAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Identity} from "../../components/Identity";
import {Word} from "../../components/Word";
import {editListItem, removeListItem, selectList} from "../../features/list/listSlice";
import {selectTerms} from "../../features/supplyFilters/supplyFiltersSlice";
import {faCircle} from "@fortawesome/free-regular-svg-icons";
import {useNavigate, useParams} from "react-router-dom";

let buttonTypeHandler;
let setIsSavingHandler;

function HeaderAction({saveHandler}) {

    let {supplyId} = useParams();
    const [buttonType, setButtonType] = useState("REFRESH");
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let headerAction = "";

    buttonTypeHandler = setButtonType;
    setIsSavingHandler = setIsSaving;

    const handleRefresh = () => {

        if (!supplyId) {
            return false;
        }

        API.refreshSupplies("IDENTITY", [supplyId])
            .then((res) => {
                if (res.status !== 200 || !res.data || (res.data && res.data.status !== null)) {
                    if (res.data && res.data.message) {
                        throw new Error(res.data.message);
                    }
                }

                dispatch(addAlert({
                    identifier: "suppliesAlerts",
                    type: res.data.status === "OK" ? "success" : "danger",
                    message: res.data.status === "OK" ? "Succesvol ververst" : res.data.message,
                }));

                if (res.data.status === "OK") {
                    navigate("/eigen-aanbod");
                }

            })
            .catch((error) => {
                console.error("Refresh update for " + supplyId + " failed:", error.message);
            });


    }

    const triggerSubmit = (ev) => {
        setIsSaving(true);
        if (saveHandler) {
            saveHandler(ev);
        }
    };

    if (buttonType) {
        switch (buttonType.toUpperCase()) {
            case 'SAVE':
                if (!isSaving) {
                    headerAction = <button className={"button button--primary d-block p-1 h-auto"}
                                           onClick={triggerSubmit}>Opslaan</button>;
                } else {
                    headerAction = "Opslaan...";
                }
                break;
            case 'REFRESH':
                if (supplyId) {
                    headerAction = <button className={"button bg-secondary text-white d-block p-1 h-auto"}
                                           onClick={handleRefresh}>
                        <span className={"icon me-1"}><FontAwesomeIcon icon={faRedoAlt}/></span>
                        Verversen
                    </button>;
                }
                break;
        }
    }

    return headerAction;

}

class SupplyEdit extends Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            supplyId: null,
            data: {},
            formData: {},
            isLoaded: false,
            isSaved: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnKeyUp = this.handleOnKeyUp.bind(this);
        this.updateStoredList = this.updateStoredList.bind(this);

        this.sliderRef = createRef(null);

    }

    componentDidMount() {
        const supplyId = this.props.params.supplyId;

        document.title = "Bewerken" + PAGE_TITLE_BASE

        this.getData(supplyId);
    }

    /**
     *
     * @param event
     */
    handleChange = ({target}) => {
        let {name, value} = target;

        if (target.type === 'checkbox') {
            // check if checkbox is checked. that's a lot of check in that sentence
            value = target.checked
        }

        if (target.getAttribute("data-float")
            && target.getAttribute("data-float") === true) {

            value = parseFloat(value.replace(",", ".").toFixed(2));
        }

        if (value === "") {
            value = null;
        }

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        });
    };

    handleOnKeyUp = () => {
        buttonTypeHandler("SAVE");
        this.setState({isSaved: false});
    }

    /**
     *
     * @param supplyId
     */
    getData = (supplyId) => {

        API.fetchSupply(supplyId)
            .then((res) => {

                if (res && res.data && res.data.status === "OK") {
                    this.setState({
                        supplyId: supplyId,
                        isLoaded: true,
                        data: res.data,
                        // formData: res.data
                        formData: res.data
                    });
                } else {

                    throw res.data.message;
                }


            })
            .catch((err) => {
                this.props.navigate("/500")
            });
    }

    /**
     *
     * @param result
     */
    updateStoredList = async (result) => {
        const storedList = selectList(this.props, "IDENTITY");

        if (!storedList) {
            return false;
        }

        const terms = selectTerms(this.props, "IDENTITY");

        /**
         * If the new data has zero quantity but your list doesn't show zero quantity,
         * it should be removed from the list
         */
        if (terms["showZeroQuantity"] === false && parseInt(result.amountTotal) === 0) {
            this.props.dispatch(removeListItem({
                identifier: "IDENTITY",
                itemId: storedList.listItemIndex,
            }));
        } else {

            const changedList = {
                identifier: "IDENTITY",
                itemId: storedList.listItemIndex,
                value: {
                    quantity: parseInt(result.amountTotal),
                    unitPrice: result.unitPrice,
                    price: result.unitPrice !== null
                        ? "&euro;&nbsp;" + result.unitPrice.replace(".", ",")
                        : <Word tag={"SUPPLY-LIST-ON-REQUEST"}/>
                }
            }

            this.props.dispatch(editListItem(changedList));

        }
    }


    /**
     *
     * @param event
     */
    handleSubmit = event => {
        event.preventDefault();

        const {supplyId, formData} = this.state;

        this.props.dispatch(clearAlertsByIdentifier("suppliesAlerts"));
        this.props.dispatch(clearAlertsByIdentifier("formAlerts"));

        API.editSupply(supplyId, formData).then((res) => {

            this.props.dispatch(addAlert({
                identifier: res.data.status === "OK" ? "suppliesAlerts" : "formAlerts",
                type: res.data.status === "OK" ? "success" : "danger",
                message: res.data.status === "OK" ? "Aanbod succesvol bijgewerkt" : "FORM-ERROR-GENERALMESSAGE",
            }));

            if (res.data.status === "OK") {

                this.updateStoredList(res.data.data)
                    .then(r => this.props.navigate("/eigen-aanbod"));

            } else {
                setIsSavingHandler(false);
                this.setState({isSaved: false});
            }

        });
    }

    render() {

        const {data} = this.state;
        const {plant, images} = data;

        const header = <HeaderReturn
            handleOnClick={(this.state.isSaved === false
                ? () => {

                    this.props.dispatch(clearAlertsByIdentifier("formAlerts"));

                    this.props.dispatch(addAlert({
                        identifier: "suppliesAlerts",
                        type: "warning",
                        message: "SUPPLY-EDIT-ALERT-CANCELLED",
                    }));

                    this.props.navigate("/eigen-aanbod");

                } : "/eigen-aanbod")}
            action={<HeaderAction saveHandler={this.handleSubmit}/>}
        />

        if (this.state.isLoaded !== true) {
            return (
                <Fragment>

                    <Identity/>

                    <AppContainer>
                        {header}
                    </AppContainer>

                </Fragment>
            )
        }

        const galleryUri = `/eigen-aanbod/${this.state.supplyId}/gallerij`;
        const goToGallery = () => {

            if (this.state.isSaved === false) {
                if (window.confirm("Weet u zeker dat u dit scherm wilt verlaten? Uw wijzigingen worden niet opgeslagen...")) {
                    this.props.dispatch(clearAlertsByIdentifier("formAlerts"));
                    this.props.navigate(galleryUri);
                }
            } else {
                this.props.dispatch(clearAlertsByIdentifier("formAlerts"));
                this.props.navigate(galleryUri);
            }

        }

        let diff = 3 - images.length;

        let placeholders = [];

        if (diff > 0) {
            placeholders = Array.from(Array(diff).keys()).map(() => (
                <div
                    className={"placeholder text-center d-flex align-items-center justify-content-center border border-grey"}
                    onClick={goToGallery}>
                    <span className="fa-stack text-grey" style={{verticalAlign: "top"}}>
                        <FontAwesomeIcon icon={faCircle} className={"fa-stack-2x"}/>
                        <FontAwesomeIcon icon={faPlus} className={"fa-stack-1x"}/>
                    </span>

                </div>
            ));

        }


        return (
            <Fragment>

                <Identity/>

                <AppContainer>

                    {header}

                    <Content bb={true}>
                        <h6 className="fw-bold mb-1">{plant.plantName}</h6>
                        <ListItemRow label={plant.displayQuality}/>
                    </Content>

                    <Content bb={true}>
                        <Form onSubmit={this.handleSubmit} onKeyUp={this.handleOnKeyUp}>

                            <Alerts disableClear={true} identifier={"formAlerts"} types={["danger"]}/>

                            <div className="form__component form__component--fieldset align-items-start">
                                <div className="form__component__column form__component__column--50">

                                    <FormLabel title={"Aantal"}/>
                                    <FormElementInput required={true} attr={{
                                        name: "amountTotal",
                                        defaultValue: data.amountTotal,
                                        onChange: this.handleChange
                                    }}/>

                                </div>
                                <div className="form__component__column form__component__column--50">
                                    <FormLabel title={"Prijs per stuk"}/>
                                    <FormElementInput required={false} attr={{
                                        name: "unitPrice",
                                        defaultValue: (data.unitPrice ? data.unitPrice.replace(".", ",") : null),
                                        onChange: this.handleChange,
                                        "data-float": true
                                    }}/>
                                </div>
                            </div>
                        </Form>
                    </Content>

                    {images.length < 1
                        ? (<Content bb={true}>
                            <div className={"d-flex justify-content-between align-items-center mb-2"}>
                                <div className="results__overview__list__item__title"><Word tag={"SUPPLY-EDIT-IMAGES"}/>
                                </div>

                                <button className={"btn btn-outline-primary"}
                                        onClick={goToGallery}>
                                    <span className={"me-1"}><FontAwesomeIcon icon={faCamera}/></span>
                                    Toevoegen
                                </button>


                            </div>
                            <Alerts identifier={"galleryAlerts"} timeOut={5000}/>
                        </Content>)
                        : (<React.Fragment>
                            <Content>

                                <div className={"d-flex justify-content-between align-items-center"}>
                                    <div className="results__overview__list__item__title">
                                        <Word tag={"SUPPLY-EDIT-IMAGES"}/>
                                    </div>

                                    <button className={"btn btn-outline-primary"} onClick={goToGallery}>
                                        <span className={"me-1"}><FontAwesomeIcon icon={faCamera}/></span>
                                        Bewerken
                                    </button>
                                </div>

                            </Content>
                            <Content bb={true}>

                                <Alerts identifier={"galleryAlerts"} timeOut={5000}/>

                                <div className="gallery">

                                    <div className="gallery__images">
                                        <div className={"gallery__images__container"}>
                                            <Carousel slides={images} placeholders={placeholders} settings={{
                                                slidesToShow: 5,
                                                centerMode: false,
                                                responsive: [
                                                    {
                                                        breakpoint: 768,
                                                        settings: {
                                                            slidesToShow: 3,
                                                        }
                                                    },
                                                    {
                                                        breakpoint: 480,
                                                        settings: {
                                                            slidesToShow: 3,
                                                        }
                                                    }
                                                ]
                                            }}/>

                                        </div>
                                    </div>
                                </div>

                            </Content>
                        </React.Fragment>)
                    }


                    <SupplyDetails data={data}/>

                </AppContainer>
            </Fragment>

        );
    }


}

const MapStateToProps = (state) => ({
    user: state.user,
    alerts: state.alerts,
    supplyFilters: state.supplyFilters,
    translate: state.translate,
    list: state.list
});

export default connect(MapStateToProps)(withNavigate(SupplyEdit));
