import React, {useEffect, useRef, useState} from "react";
import {FixedSizeList} from "react-window";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleUp} from "@fortawesome/free-solid-svg-icons";
import InfiniteLoader from "react-window-infinite-loader";
import ListItem from "./List/ListItem";

/**
 *
 * @return {JSX.Element}
 * @constructor
 * @param props
 */
const List = React.memo((props) => {

    const {
        results,
        dataLength,
        handleListItem,
        handleOnClick,
        setStoredList,
        storedListOffsetTop,
        loadNextPage
    } = props;

    // We create a reference for the InfiniteLoader
    const listRef = useRef(null);

    const LOADING = 1;
    const LOADED = 2;
    let itemStatusMap = {};

    const container = document.querySelector(".inner-wrapper");
    const [scrollUpButton, setScrollUpButton] = useState(false);
    const [containerSizes, setContainerSizes] = useState(() => {
        return {
            height: container.clientHeight,
            width: container.clientWidth
        }
    })

    const onScroll = ({scrollOffset}) => {
        if (scrollOffset >= 100 && scrollUpButton === false) {
            setScrollUpButton(true);
        } else if (scrollOffset < 100 && scrollUpButton === true) {
            setScrollUpButton(false);
        }
    };

    useEffect(() => {

        window.addEventListener('resize', () => {
            setContainerSizes({
                height: container.clientHeight,
                width: container.clientWidth
            })
        });

    }, [container]);

    useEffect(() => {
        const scrollToListItem = (storedListOffsetTop) => {
            listRef.current.scrollTo(storedListOffsetTop);
        }

        if (storedListOffsetTop && dataLength > 0) {
            scrollToListItem(storedListOffsetTop)
        }

    }, [storedListOffsetTop, dataLength])

    const scrollUp = (scrollUpButton ? <button
        onClick={() => document.querySelector(".results-wrapper").scrollTo(0, 0)}
        className={"button button--primary position-fixed shadow"}
        style={{
            right: "60px",
            bottom: "100px",
            zIndex: "2"
        }}>
        <FontAwesomeIcon icon={faAngleDoubleUp}/>
    </button> : null);

    const isItemLoaded = index => !!results[index];

    /**
     *
     * @param index
     * @param style
     * @return {JSX.Element}
     * @constructor
     */
    const Row = ({index, style}) => {

        let listItem;
        if (results[index]) {
            const item = results[index];
            listItem = handleListItem(item, index, handleOnClick, setStoredList);
        } else {
            listItem = <ListItem actions={{left: [], right: []}}>
                <div className="results__overview__list__item__row">
                    <div className="results__overview__list__item__text results__overview__list__item__title">
                        Aan het laden...
                    </div>
                    <div className="results__overview__list__item__text"/>
                </div>
            </ListItem>
        }

        return <div style={style} key={index} data-item-id={index}>
            {listItem}
        </div>

    }

    /**
     *
     * @param startIndex
     * @param stopIndex
     * @return {Promise<unknown>}
     */
    const loadMoreItems = (startIndex, stopIndex) => {

        for (let index = startIndex; index <= stopIndex; index++) {
            itemStatusMap[index] = LOADING;
        }

        return new Promise(resolve =>
            setTimeout(() => {

                const pageNr = Math.ceil((stopIndex / 500));
                loadNextPage(pageNr)

                for (let index = startIndex; index <= stopIndex; index++) {
                    itemStatusMap[index] = LOADED;
                }
                resolve();
            }, 1000)
        );
    };

    return (

        <React.Fragment>

            <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={dataLength}
                loadMoreItems={loadMoreItems}
                minimumBatchSize={500}
                threshold={250}
            >
                {({onItemsRendered, ref}) => (
                    <FixedSizeList
                        className={"results-wrapper"}
                        itemCount={dataLength}
                        height={containerSizes.height}
                        width={containerSizes.length}
                        itemSize={67}
                        onItemsRendered={onItemsRendered}
                        onScroll={onScroll}
                        ref={list => {
                            ref(list); // Give InfiniteLoader a reference to the list
                            // @ts-ignore
                            listRef.current = list; // Set your own ref to it as well.
                        }}
                    >
                        {Row}
                    </FixedSizeList>
                )}
            </InfiniteLoader>

            {scrollUp}


        </React.Fragment>


    );
});

export default List;
