import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import FsLightbox from 'fslightbox-react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {IMAGE_BASE} from "../API";

/**
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const Carousel = (props) => {

    const [productIndex, setProductIndex] = useState(0);
    // if toggler is updated when lightbox is closed it will open it
    // if toggler is updated when lightbox is opened it will close it
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    /**
     *
     * @param number
     */
    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    const {slides} = props;

    useEffect(() => {
        setProductIndex((productIndex) => productIndex + 1);
    }, [slides])

    /**
     *
     * @param onClick
     * @param className
     * @returns {JSX.Element}
     * @constructor
     */
    function PrevButton({onClick, className}) {

        return <div onClick={onClick}
                    style={(className.includes("disabled") ? {color: "#ddd"} : {})}
                    className={className + " pe-1 " + (className.includes("disabled") ? "" : " text-secondary")}>
            <FontAwesomeIcon icon={faChevronLeft}/>
        </div>;
    }

    /**
     *
     * @param onClick
     * @param className
     * @returns {JSX.Element}
     * @constructor
     */
    function NextButton({onClick, className}) {
        return <div onClick={onClick}
                    style={(className.includes("disabled") ? {color: "#ddd"} : {})}
                    className={className + " ps-1 " + (className.includes("disabled") ? "" : " text-secondary")}>
            <FontAwesomeIcon icon={faChevronRight}/>
        </div>;

    }

    let settings = {
        infinite: false,
        className: "product-carousel__slider d-flex align-items-center",
        centerPadding: '0px',
        prevArrow: <PrevButton/>,
        nextArrow: <NextButton/>,
        swipe: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    centerMode: true,
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: true,
                    slidesToShow: 1
                }
            }
        ]
    };

    if (props.settings) {
        settings = {
            ...settings,
            ...props.settings
        }
    }

    let thumbnailSize = 400, placeholders = [];

    if (props.thumbnailSize) {
        thumbnailSize = props.thumbnailSize;
    }
    if (props.placeholders) {
        placeholders = props.placeholders;
    }

    const sources = slides.map((slide) => (slide.uri ?? IMAGE_BASE + "/guid/") + "/type/preview-existing/directive");
    const thumbnails = sources.map((slide) => slide + "/scalearwithin-" + thumbnailSize + "x" + thumbnailSize);
    const large = sources.map((slide) => slide + "/scalearwithin-800x800");

    let attributes = [];

    if (large && large.length > 0) {
        for (let i = 0; i < large.length; i++) {

            attributes = [...attributes, {
                decoding: "async",
                draggable: false,
            }]
        }
    }

    return (
        <div className="product-carousel">

            <Slider ref={props.sliderRef} {...settings}>

                {thumbnails.map((source, i) => {
                    return (
                        <div className="product-carousel__slide" onClick={() => openLightboxOnSlide(i + 1)}>
                            <img src={source} alt={"Afbeelding"} decoding={"sync"} draggable={false}/>
                        </div>
                    )
                })}

                {placeholders}
            </Slider>

            <FsLightbox
                toggler={lightboxController.toggler}
                type="image"
                slide={lightboxController.slide}
                sources={large}
                key={productIndex}
                customAttributes={attributes}
            />

        </div>

    );
}

export default Carousel;
