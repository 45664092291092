import React from "react";
import {useFormErrorClass} from "../../FormFromArray";

/**
 *
 * @param attr
 * @param hasError
 * @param field
 * @return {JSX.Element}
 * @constructor
 */
export function FormElementInput({attr, hasError, field}) {
    const className = useFormErrorClass(attr, hasError);
    return <input type="text" {...attr} className={className} {...field}/>
}
