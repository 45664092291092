import React, {Fragment, useEffect, useState} from "react";
import List from "../List";
import {Lightbox} from "../../features/lightbox/Lightbox";
import Loader from "../Loader";
import {Content} from "../Content";
import {selectTerms} from "../../features/supplyFilters/supplyFiltersSlice";
import API from "../../API";
import ListItem from "../List/ListItem";
import {useDispatch, useSelector} from "react-redux";
import {selectSelected} from "../../features/autocomplete/autocompleteSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {addList, selectList, setList} from "../../features/list/listSlice";
import {Alerts} from "../../features/alerts/Alerts";

export const SuppliesList = React.memo((props) => {

    const {
        formData,
        allowSelectedFalse = true,
        allowEmptyTerms = true,
        loadedDefault = true,
        handleListItem,
        handleTerms,
        submenu,
        supplyId,
        postOnClick,
        minQueryLength = 0
    } = props;

    const identifier = props.identifier || props.formData.context;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const terms = useSelector((state) => selectTerms(state, formData.context))
    const selected = useSelector((state) => selectSelected(state, formData.context))
    const storedList = useSelector((state) => selectList(state, identifier))

    if (!storedList) {
        dispatch(addList({
            identifier: identifier
        }));
    }

    const [trigger, setTrigger] = useState(false);
    const [page, setPage] = useState(() => {

        let initialState = 1;

        if (storedList
            && storedList.currentPage) {

            initialState = storedList.currentPage;
        }

        return initialState;
    });

    const [isLoaded, setIsLoaded] = useState(() => {
        let initialState = false;
        if (storedList) {
            initialState = true;
        }

        return initialState;
    });

    let initialState = {
        error: null,
        pages: [],
        scrollLast: 0,
        total: 0,
        totalPages: null,
        hasStored: false,
        stopLoading: false
    };

    const [state, setState] = useState(() => {

        if (storedList
            && storedList.pages
            && storedList.pages.length > 0) {

            initialState = {
                ...initialState,
                pages: storedList.pages,
                total: storedList.totalResults,
                totalPages: storedList.totalPages,
                hasStored: true
            }
        }

        return initialState;

    });
    const [requestedPageNrs, setRequestedPageNrs] = useState(() => {
        let initialState = {};

        if (storedList
            && storedList.requestedPageNrs) {

            initialState = storedList.requestedPageNrs;
        }

        return initialState;
    });

    const setStoredList = (ev) => {

        const supplyId = getSupplyId(ev);

        let target = ev.target;
        let listItemIndex = target.closest(".results__overview__list__item").parentNode.getAttribute("data-item-id");

        // some action...

        const container = document.querySelector(".results-wrapper");

        try {
            dispatch(setList({
                identifier: identifier,
                pages: state.pages,
                listItemIndex: parseInt(listItemIndex),
                supplyId: supplyId,
                supply: state.pages[parseInt(listItemIndex)],
                currentPage: page,
                storedListOffsetTop: container.scrollTop,
                totalPages: state.totalPages,
                totalResults: state.total,
                requestedPageNrs: requestedPageNrs
            }));

        } catch (e) {
            console.log(e)
        }

    }

    /**
     *
     * @param target
     * @return {string}
     */
    const getSupplyId = ({target}) => {
        return target.closest(".results__overview__list__item").getAttribute("data-supply-id");
    }

    /**
     *
     * @param ev
     */
    const handleOnClick = ev => {

        setStoredList(ev);

        const supplyId = getSupplyId(ev);

        // dispatch(setSelected({
        //     identifier: formData.context,
        //     value: [{
        //         repeatSearchQuery: formData.terms
        //     }]
        // }));

        // then redirect
        if (postOnClick) {
            postOnClick(supplyId)
        } else {
            navigate(`${location.pathname}/${supplyId}`)
        }
    }

    const handleNext = (pageNr) => isLoaded ? setPage(pageNr) : true;

    const getResults = () => {

        let results;

        if (state.pages.length > 0) {

            results = <List
                dataLength={state.total}
                results={state.pages}
                handleListItem={handleListItem}
                isLoading={isLoaded === false}
                handleOnClick={handleOnClick}
                setStoredList={setStoredList}

                // Callback function responsible for loading the next page of items.
                loadNextPage={handleNext}
                // Are there more items to load?
                // (This information comes from the most recent API request.)
                hasNextPage={true}
                // Are we currently loading a page of items?
                // (This may be an in-flight flag in your Redux store for example.)
                isNextPageLoading={true}
                storedListOffsetTop={storedList.storedListOffsetTop}
            />;

        } else if ((formData.terms === "" || formData.terms.length < minQueryLength) && handleTerms) {

            results = handleTerms();

        } else {
            if(state.stopLoading) {
                results = <ListItem key={"supply-list-no-results"} actions={{left: [], right: []}}>
                        <div className="results__overview__list__item__row">
                            <div className="results__overview__list__item__text results__overview__list__item__title">
                                Er zijn geen resultaten gevonden
                            </div>
                            <div className="results__overview__list__item__text"/>
                        </div>
                    </ListItem>;
           }
           else {
                results = <ListItem key={"supply-list-no-results"} actions={{left: [], right: []}}>
                    <div className="results__overview__list__item__row">
                        <div className="results__overview__list__item__text results__overview__list__item__title">
                            Laden
                        </div>
                        <div className="results__overview__list__item__text"/>
                    </div>
                </ListItem>;
           }
            
        }

        return results;

    }

    useEffect(() => {

        if (storedList && storedList.triggerRefresh) {
            setPage(1);
            setState(initialState);
            setRequestedPageNrs({});
            setTrigger(true);
        }
    }, [trigger, storedList, setTrigger, setState]);

    useEffect(() => {

        if (state.hasStored === true && requestedPageNrs[page] && trigger === false) {
            return true;
        }

        setIsLoaded(false)

        /**
         *
         * @param data
         */
        const mapSuppliesList = (data) => {

            setState((state) => {

                let resultObj = [...state.pages];

                if (resultObj.length < 1) {
                    resultObj = Array.from(new Array(parseInt(data.total)).keys()).map(() => null);
                }

                let resultIndex = 0;

                for (let i = data.start; i < (data.stop + 1); i++) {

                    resultObj[i] = data.supplies[resultIndex];
                    resultIndex++;
                }

                return {
                    ...state,
                    pages: resultObj,
                    totalPages: parseInt(data.pages),
                    total: parseInt(data.total)
                }
            });

        }

        /**
         *
         * @param activePage
         */
        const getData = (activePage) => {

            if (activePage < 1 || (state.totalPages && activePage > state.totalPages) || requestedPageNrs[activePage]) {
                return false;
            }

            const postData = {
                ...formData,
                searchType: "PLANT",
                page: activePage,
                filters: terms
            };

            if (selected && selected[0]) {

                if (typeof selected[0]["seachType"] !== "undefined") {

                    Object.assign(postData, {
                        "searchType": selected[0].searchType
                    });
                }

                if (typeof selected[0]["ownerEntityId"] !== "undefined") {

                    postData.filters = {
                        ...postData.filters,
                        ownerEntityId: parseInt(selected[0]["ownerEntityId"])
                    }
                }
            }

            API.fetchSupplies(postData)
                .then((res) => {

                    if (!res) {
                        return false;
                    }

                    if (!res.data.supplies && res.data.message) {
                        throw new Error(res.data.message);
                    }

                    if (res.data.supplies.length > 0) {

                        /**
                         * Merge results on the right place
                         */
                        mapSuppliesList(res.data)
                        setIsLoaded(true);

                    } else if (activePage >= state.totalPages) {
                        // just stop searching for more, there is not
                        setState((state) => ({
                            ...state,
                            stopLoading: true
                        }));

                    } else {

                        setState((state) => ({
                            ...state,
                            pages: [],
                            total: 0
                        }))

                    }

                });


        }

        /**
         The if statement literally means
         - selected is not false unless setting selected can be false
         - terms is not empty unless setting terms can be empty

         Why? To prevent a LOT of XHR
         */
        if ((selected !== false || allowSelectedFalse === true)
            && (formData.terms !== "" || allowEmptyTerms === true)
            && formData.terms.length >= minQueryLength) {

            getData(page);

        } else {

            setState((state) => ({
                ...state,
                pages: [],
                total: 0
            }));

            setIsLoaded(true)
            setPage(1);

        }


    }, [trigger, requestedPageNrs, setRequestedPageNrs, storedList, allowSelectedFalse, allowEmptyTerms, selected, terms, page, setState, setIsLoaded, formData])

    if (isLoaded !== true
        && (!loadedDefault || loadedDefault !== true)) {

        return <Content><Loader/></Content>
    } else {

        return <Fragment>

            <Alerts identifier={"suppliesAlerts"}/>

            {getResults()}

            {submenu}

            <Lightbox identifier={supplyId}/>

        </Fragment>
    }


});
