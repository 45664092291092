import React from "react";
import {IMAGE_BASE} from "../../API";
import {DragList} from "./DragList";

/**
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const ImageGrid = (props) => {

    const {slides, addHandler, gridHandlers} = props;

    let thumbnailSize = 400;

    if (props.thumbnailSize) {
        thumbnailSize = props.thumbnailSize;
    }

    const sources = slides.map((slide) => (slide.uri ?? IMAGE_BASE + "/guid/") + "/type/preview-existing/directive");
    const gridItems = sources.map((slide) => slide + "/scalearwithin-" + thumbnailSize + "x" + thumbnailSize);
    gridItems.forEach((item, index) => {
        gridItems[index] = (
            <div className={"image__wrapper position-relative"}>
                <img src={item} decoding={"sync"} alt={""}/>
            </div>
        );
    });

    return (
        <DragList
            items={gridItems}
            addHandler={addHandler}
            itemActions={gridHandlers}
            onSortEnd={gridHandlers.onSortEnd}
        />
    );
}

export default ImageGrid;
