import {createSlice} from '@reduxjs/toolkit';

export const LightboxSlice = createSlice({
    name: 'lightbox',
    initialState: {
        open: false,
        images: {}
    },
    reducers: {
        setOpen: (state = this.initialState, action) => {
            state.open = action.payload;
        },
        setImages: (state = this.initialState, action) => {

            const {identifier, items} = action.payload;

            state.images = {
                [identifier]: items
            };
        },
    },
});

export const {setOpen, setImages} = LightboxSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectOpen = state => state.lightbox.open;
export const selectImages = state => state.lightbox.images;

export default LightboxSlice.reducer;
