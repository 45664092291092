import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Alert = (props) => (
    <div className={"alert alert-" + props.type}>
        {props.icon && <FontAwesomeIcon icon={props.icon} className={"me-1"} />}
        {props.message}
    </div>
);

export default Alert;
