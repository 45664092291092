import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import AppContainer from "../../components/AppContainer";
import {Content} from "../../components/Content";
import {HeaderReturn} from "../../layout/Header";
import {Identity} from "../../components/Identity";
import {useNavigate, useParams} from "react-router-dom";
import API, {addSupply, editSupplyAdvanced, fetchAutocompleteOptions, fetchFormDataBySupply} from "../../API";
import Loader from "../../components/Loader";
import {FormFromArray} from "../../components/FormFromArray";
import {useDispatch, useSelector} from "react-redux";
import {addAlert, clearAlertsByIdentifier} from "../../features/alerts/alertsSlice";
import {selectWords} from "../../features/translate/translateSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedoAlt} from "@fortawesome/free-solid-svg-icons";
import {editListItem, removeListItem, selectList, triggerRefresh} from "../../features/list/listSlice";
import {selectTerms} from "../../features/supplyFilters/supplyFiltersSlice";
import {setIsBlocked, getIsBlocked} from "../../app/config";

let buttonTypeHandler;
let isSavingHandler;

function HeaderAction({options = {}}) {

    let {supplyId} = useParams();
    const [buttonType, setButtonType] = useState("REFRESH");
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {refSubmitButton} = options;

    let headerAction = "";

    buttonTypeHandler = setButtonType;
    isSavingHandler = setIsSaving;

    const handleRefresh = () => {

        if (!supplyId) {
            return false;
        }

        API.refreshSupplies("IDENTITY", [supplyId])
            .then((res) => {
                if (res.status !== 200 || !res.data || (res.data && res.data.status !== null)) {
                    if (res.data && res.data.message) {
                        throw new Error(res.data.message);
                    }
                }

                dispatch(addAlert({
                    identifier: "suppliesAlerts",
                    type: res.data.status === "OK" ? "success" : "danger",
                    message: res.data.status === "OK" ? "Succesvol ververst" : res.data.message,
                }));

                if (res.data.status === "OK") {
                    navigate("/eigen-aanbod");
                }

            })
            .catch((error) => {
                console.error("Refresh update for " + supplyId + " failed:", error.message);
            });


    }

    const triggerSubmit = () => {
        setIsSaving(true);
        refSubmitButton?.current?.click();
    };

    if (buttonType) {
        switch (buttonType.toUpperCase()) {
            case 'SAVE':
                if (!isSaving) {
                    headerAction = <button className={"button button--primary d-block p-1 h-auto"}
                                           onClick={triggerSubmit}>Opslaan</button>;
                } else {
                    headerAction = "Opslaan...";
                }
                break;
            case 'REFRESH':
                if (supplyId) {
                    headerAction = <button className={"button bg-secondary text-white d-block p-1 h-auto"}
                                           onClick={handleRefresh}>
                        <span className={"icon me-1"}><FontAwesomeIcon icon={faRedoAlt}/></span>
                        Verversen
                    </button>;
                }
                break;
        }
    }

    return headerAction;

}

const setDataTypes = (fields) => {
    fields.forEach((field, index) => {
        if (field.attributes?.name === "amountPurchaseMin"
            || field.attributes?.name === "amountTotal"
            || field.attributes?.name === "featureLft1"
            || field.attributes?.name === "featureLft2"
            || field.attributes?.name === "featurePkw"
            || field.attributes?.name === "featureSize"
            || field.attributes?.name === "loading"
            || field.attributes?.name === "deliveryDuration") {

            fields[index].dataType = "INTEGER";
        }

        if (field.attributes?.name?.includes("loading-")
            && !field.subElements?.length
            && field.attributes?.type !== "checkbox") {

            field.required = true;
        }

        if (field.subElements?.length) {
            setDataTypes(field.subElements); // recursive call
        }

    })
}

function SupplyEditForm({initialValues, refSubmitButton, fields, fieldset}) {

    let {supplyId} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const storedList = useSelector((state) => selectList(state, "IDENTITY"));
    const terms = useSelector((state) => selectTerms(state, "IDENTITY"));
    const setButtonType = buttonTypeHandler;

    if (fields) {
        setDataTypes(fields);
    }

    const handleSubmit = (data) => {

        const callback = (res) => {

            if (res.data.status === "OK") {

                dispatch(addAlert({
                    identifier: "uploadAlerts",
                    type: "success",
                    message: `LABEL-SUPPLY-ACTION-${(supplyId ? "EDIT" : "ADDED")}-SUCCESSFUL`
                }));

                if (storedList && storedList.listItemIndex && storedList.listItemIndex.toString().length > 0) {

                    /**
                     * If the new data has zero quantity but your list doesn't show zero quantity,
                     * it should be removed from the list
                     */
                    if (terms["showZeroQuantity"] === false && parseInt(data.amountTotal) === 0) {
                        dispatch(removeListItem({
                            identifier: "IDENTITY",
                            itemId: storedList.listItemIndex,
                        }));
                    }

                }

                if (!supplyId) {
                    supplyId = res.data.data.supplyId;
                }

                API.fetchSupply(supplyId)
                    .then((supplyRes) => {

                        if (storedList?.listItemIndex?.toString().length > 0) {

                            const changedList = {
                                identifier: "IDENTITY",
                                itemId: storedList.listItemIndex,
                                value: {
                                    ...supplyRes.data,
                                    price: supplyRes.data.displayPrice,
                                    title: supplyRes.data.plant.plantName,
                                    hasPhoto: supplyRes.data.images.length,
                                    quantity: parseInt(supplyRes.data.amountTotal),
                                }
                            }

                            dispatch(editListItem(changedList));
                        } else {
                            dispatch(triggerRefresh({
                                identifier: "IDENTITY",
                                value: true
                            }))
                        }

                        setIsBlocked(false)
                        navigate("/eigen-aanbod/" + res.data.data.supplyId + "/gallerij");

                    })
                    .catch((err) => {

                        dispatch(addAlert({
                            identifier: "formAlerts",
                            type: "danger",
                            message: err,
                        }));

                        isSavingHandler(false)
                    });


            } else {

                dispatch(addAlert({
                    identifier: "formAlerts",
                    type: "danger",
                    message: res.data.message,
                }));

                isSavingHandler(false);

            }

        }

        if (data.hasLabel?.toString().length > 0) {
            data.hasLabel = true;
        }

        const catchHandler = (err) => {

            dispatch(addAlert({
                identifier: "formAlerts",
                type: "danger",
                message: "Er is een fout opgetreden"
            }));

            isSavingHandler(false)
        }

        if (!supplyId) {
            addSupply(data).then(callback).catch(catchHandler);
        } else {
            editSupplyAdvanced(supplyId, data).then(callback).catch(catchHandler);
        }
    }

    return <FormFromArray
        prefill={supplyId ? initialValues : {
            amountPurchaseMin: 1,
            hasLabel: true,
            deliverableDateType: "DIRECT"
        }}
        handlers={{onSubmit: handleSubmit, isSavingHandler: isSavingHandler}}
        dependencies={fieldset.dependencies}
        dynamicFields={fieldset.dynamicFields}
        rules={fieldset.rules}
        fields={fields}
        onValueChange={(values) => {

            setIsBlocked(true);

            if (values.plantSearch
                && values.featureSize
                && values.featurePkw
                && values.amountTotal?.toString().length > 0) {

                setButtonType("SAVE");
            }
        }}
        customSubmit={<button hidden={true} ref={refSubmitButton} type={"submit"}/>}
    />

}

export function SupplyEdit() {

    let {supplyId} = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [fieldset, setFieldset] = useState({
        fields: [],
        dependencies: {}
    });
    const words = useSelector(selectWords);
    const [initialValues, setInitialValues] = useState(null);
    const refSubmitButton = useRef(null);
    const dispatch = useDispatch();

    setIsBlocked(false);

    const fetchFormValues = useCallback(async () => {
        if (!supplyId) {
            return false;
        }

        await fetchFormDataBySupply(supplyId)
            .then(async ({data}) => {
                if (data && data.status === 'OK') {

                    let formData = data.data;

                    /*
                    Run autocompleter
                     */
                    const searchTerm = formData.plantSearchTerm ?? "";
                    const expectedId = formData.plantExpectedId ?? null;

                    if (searchTerm && expectedId) {
                        await fetchAutocompleteOptions(searchTerm, "PLANT")
                            .then(({data}) => {
                                const options = data.results;

                                /*
                                Map trough options to check for expected plant
                                 */
                                options.forEach((option) => {
                                    if (parseInt(option.identifier) === expectedId) {

                                        formData = {
                                            ...formData,
                                            plantSearch: [option]
                                        }
                                    }
                                });
                            });
                    }

                    setInitialValues(formData);

                }
            })

    }, [supplyId]);

    const fetchData = useCallback(async () => {
        await API.fetchSupplyForm()
            .then(async (res) => {
                const {data} = res.data;

                if (res.data.status !== "OK") {
                    return false;
                }

                if (supplyId) {
                    await fetchFormValues()
                }

                setFieldset(data);

            });
    }, [supplyId])

    useEffect(() => {
        setIsLoading(true);
        fetchData().then(() => setIsLoading(false));
    }, [fetchData, supplyId, setIsLoading])

    let fields = [
        {
            attributes: {
                type: "plantSearch",
                name: "plantSearch",
                id: "plantSearch",
            },
            required: true,
            label: "Plantnaam zoeken",
            subElements: [],
        },
        ...fieldset.fields,
        {
            attributes: {
                type: "html",
            },
            content: words["PWA-SUPPLY-EDIT-ACTION-HANDLE-PHOTOS-HELPER-TEXT"],
            subElements: [],
        }
    ];

    if (isLoading === true) {
        return (
            <Fragment>

                <Identity/>

                <AppContainer>

                    <HeaderReturn handleOnClick={() => navigate("/eigen-aanbod")}/>

                    <div className={"content"}>
                        <div className="container">
                            <Loader/>
                        </div>
                    </div>
                </AppContainer>

            </Fragment>
        )
    }

    return (
        <React.Fragment>

            <Identity/>

            <HeaderReturn handleOnClick={() => {
                if (getIsBlocked() === false) {
                    navigate("/eigen-aanbod")
                } else {
                    if (window.confirm("Weet u zeker dat u dit scherm wilt verlaten? Uw wijzigingen worden niet opgeslagen...") === true) {

                        dispatch(clearAlertsByIdentifier("formAlerts"));

                        dispatch(addAlert({
                            identifier: "suppliesAlerts",
                            type: "warning",
                            message: "SUPPLY-EDIT-ALERT-CANCELLED",
                        }));

                        setIsBlocked(false);

                        navigate("/eigen-aanbod")
                    }
                }
            }} action={<HeaderAction supplyId={supplyId} options={{refSubmitButton}}/>}/>

            <AppContainer>
                <Content>
                    <h1>Aanbod {supplyId ? "bewerken" : "toevoegen"}</h1>
                </Content>

                <Content>

                    <SupplyEditForm
                        fields={fields}
                        fieldset={fieldset}
                        initialValues={initialValues}
                        refSubmitButton={refSubmitButton}
                    />

                </Content>

            </AppContainer>
        </React.Fragment>
    )
}
