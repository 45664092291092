import {createSlice} from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        username: '',
        token: ''
    },
    reducers: {
        setUser: (state = this.initialState, action) => {
            state.username = action.payload;
        },
        setToken: (state = this.initialState, action) => {
            state.token = action.payload;
        },
        setLocalStorage: (state, action) => {
            localStorage.setItem("AUTH-TOKEN", state.token);
            localStorage.setItem("AUTH-USERNAME", state.username);
        },
        logOut: state => {
            state.username = '';
            state.token = '';
            localStorage.clear();
            sessionStorage.clear();
        }
    },
});

export const {setUser, logOut, setToken, setLocalStorage} = userSlice.actions;

export const selectUser = state => state.user;
export const selectUsername = state => state.user.username;
export const selectToken = state => state.user.token;

export default userSlice.reducer;
