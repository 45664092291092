import {Content} from "../Content";
import {ListItemRow} from "../List/ListItemRow";
import React, {Fragment} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Word} from "../Word";

export function SupplyDetails({data}) {

    const {plant, loadingTypeSequence} = data;
    const expireDate = data.expireDate ? new Date(data.expireDate.replace(/\s/, 'T')) : null;
    const actualizationDate = data.actualizationDate ? new Date(data.actualizationDate.replace(/\s/, 'T')) : null;

    const bundlePrices = data.bundlePrices && data.bundlePrices.length > 0
        ? data.bundlePrices.map((bundle, index) => <ListItemRow kye={index} label={bundle.label} value={bundle.value}/>)
        : "";

    const getCheckedIcon = (value) => {

        let icon;

        if (parseInt(value) === 1) {
            icon = <span className={"text-success"}><FontAwesomeIcon icon={faCheck}/></span>;
        } else {
            icon = <span className={"text-danger"}><FontAwesomeIcon icon={faTimes}/></span>;
        }

        return icon;

    }

    const wordTagBase = "ROUTE-SUPPLY-DETAILS";

    return <Fragment>
        <Content bb={true}>
            <ListItemRow label={<Word tag={wordTagBase + "-AMOUNT"}/>}
                         value={data.amountTotal.toLocaleString("nl-NL") + " stuks"}/>
            <ListItemRow label={<Word tag={wordTagBase + "-DISPLAYPRICE"}/>}
                         value={data.displayPrice || "Op aanvraag"}/>
            {bundlePrices}
            <ListItemRow label={<Word tag={wordTagBase + "-AMOUNTPURCHASEMIN"}/>} value={data.amountPurchaseMin}/>
        </Content>

        <Content bb={true}>
            <ListItemRow label={<Word tag={wordTagBase + "-DISPLAYFEATURECOLLAPPEARANCE"}/>}
                         value={plant.displayFeatureCollAppearance || "-"}/>
            <ListItemRow label={<Word tag={wordTagBase + "-DISPLAYQUALITYSIZE"}/>}
                         value={plant.displayQualitySize || "-"}/>
            <ListItemRow label={<Word tag={wordTagBase + "-DISPLAYQUALITYPKW"}/>}
                         value={plant.displayQualityPkw || "-"}/>
            <ListItemRow label={<Word tag={wordTagBase + "-DISPLAYQUALITYLFT"}/>}
                         value={plant.displayQualityLft || "-"}/>
            <ListItemRow label={<Word tag={wordTagBase + "-DISPLAYQUALITYLFT2"}/>}
                         value={plant.displayQualityLft2 || "-"}/>
            <ListItemRow label={<Word tag={wordTagBase + "-DISPLAYQUALITYVISUAL"}/>}
                         value={plant.displayQualityVisual || "-"}/>
            <ListItemRow label={<Word tag={wordTagBase + "-DISPLAYREQCERTIFICATES"}/>}
                         value={plant.displayReqCertificates || "-"}/>
        </Content>

        <Content bb={true}>
            <div className="results__overview__list__item__title">
                {<Word tag={wordTagBase + "-LABEL-COMMENT"}/>}
            </div>
            <p>{data.comment}</p>
        </Content>

        <Content bb={true}>
            <ListItemRow label={<Word tag={wordTagBase + "-LABEL"}/>}
                         value={<Word tag={"LABEL-" + (data.hasLabel ? "YES" : "NO")}/>}/>
            {loadingTypeSequence.map((seq) => {

                let value = data[seq['value']];

                if (seq['isCheckbox'] && parseInt(seq['isCheckbox']) === 1) {
                    value = getCheckedIcon(value);
                }

                return <ListItemRow label={data[seq['label']]} value={value}/>
            })}
            <ListItemRow label={<Word tag={wordTagBase + "-DISPLAYDELIVERABLEDATETYPE"}/>}
                         value={data.displayDeliverableDateType || "-"}/>
            <ListItemRow label={<Word tag={wordTagBase + "-DISPLAYDELIVERYDURATION"}/>}
                         value={data.displayDeliveryDuration || "-"}/>
        </Content>

        <Content bb={true}>
            <ListItemRow label={<Word tag={wordTagBase + "-PLANTCODE"}/>} value={plant.plantCode || "-"}/>
            <ListItemRow label={<Word tag={wordTagBase + "-REGISTRATIONSTATUS"}/>}
                         value={data.registrationStatus || "-"}/>
        </Content>

        <Content>
            <ListItemRow label={<Word tag={wordTagBase + "-ACTUALIZATIONDATE"}/>}
                         value={actualizationDate ? ('0' + actualizationDate.getDate()).slice(-2) + '-' + ('0' + (actualizationDate.getMonth() + 1)).slice(-2) + '-' + actualizationDate.getFullYear() : "-"}/>
            <ListItemRow label={<Word tag={wordTagBase + "-EXPIREDATE"}/>}
                         value={expireDate ? ('0' + expireDate.getDate()).slice(-2) + '-' + ('0' + (expireDate.getMonth() + 1)).slice(-2) + '-' + expireDate.getFullYear() : "-"}/>
        </Content>
    </Fragment>

}
