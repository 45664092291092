import React from "react";

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
const Loader = () => {
    return (
        <div className="loader">
            <div className="face">
                <div className="circle"></div>
            </div>
            <div className="face">
                <div className="circle"></div>
            </div>
        </div>
    );
}

export default Loader;
