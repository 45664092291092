import {Content} from "../Content";
import React from "react";
import {ListItemRow} from "../List/ListItemRow";

export function SupplyView({data}) {

    const {plant, owner} = data;

    return <Content bb={true}>
        <div className="results__overview__list__item__title">{plant.plantName}</div>
        <ListItemRow label={"Kwaliteit"} value={plant.displayQuality} />
        <ListItemRow label={"Aantal"} value={data.amountTotal.toLocaleString("nl-NL") + " stuks"}/>
        <ListItemRow label={"Bedrijfsnaam"} value={owner.ownerName}/>
        <ListItemRow label={"Prijs per stuk af kwekerij"} value={data.displayPrice || "Op aanvraag"}/>
    </Content>
}
