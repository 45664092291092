import {createSlice} from '@reduxjs/toolkit';
import Default from "../../language/nl-NL/default";

export const TranslateSlice = createSlice({
    name: 'translate',
    initialState: {
        fetched: null,
        words: Default
    },
    reducers: {
        setFetched: (state = this.initialState, action) => {
            state.fetched = action.payload;
        },
        setWords: (state = this.initialState, action) => {
            state.words = action.payload;
        }
    },
});

export const {setFetched, setWords} = TranslateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectWords = state => state.translate.words;
export const selectFetched = state => state.translate.fetched;
export const selectWord = (state, word) => state.translate.words["PWA-" + word];

export default TranslateSlice.reducer;
