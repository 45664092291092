export function ListItemAction(props) {

    const {identifier, icon, label, extraClass} = props;

    return (
        <div
            className={"notification__actions__action px-2 text-center d-flex align-items-center notification__actions__action--" + identifier + (extraClass ? " " + extraClass : null)}
            onClick={props.onClick ?? props.onClick}>

            <span className="notification__actions__action__wrap">
                <span className="notification__actions__action__wrap__icon d-block">
                    {icon}
                </span>
                <span className="notification__actions__action__wrap__label d-block mt-1">
                    {label}
                </span>
            </span>
        </div>
    );
}