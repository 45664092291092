import React from "react";
import {FormLabel} from "./FormLabel";

/**
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export function FormComponent(props) {

    const {label, element, overrideClass, id} = props;
    const className = props.className ? " " + props.className : "";

    return (
        <div className={(overrideClass ? props.className : "form__component" + className)}
             style={props.style}>

            <FormLabel title={label} fieldId={id}/>
            {element}
        </div>
    )

}
