import {checkLogin} from "../app/config";
import {Navigate as Redirect} from "react-router-dom";
import {addAlert} from "../features/alerts/alertsSlice";
import {selectWord} from "../features/translate/translateSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, Fragment} from "react";

/**
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export function Identity(props) {

    const dispatch = useDispatch(),
        word = useSelector((state) => selectWord(state, (props.wordTag || "ALERT-SESSION-LOGIN-EXPIRED"))),
        message = props.message || word,
        login = checkLogin();

    useEffect(() => {
        if (login === false) {

            dispatch(addAlert({
                identifier: props.alertIdentifier || "auth-alerts",
                type: "warning",
                message: message
            }));
        }
    }, [login, dispatch, props, message]);

    let ret = <Fragment />;

    if (login === false) {
        ret = <Redirect to={(props.path || "/")}/>
    }

    return ret;


}
