import FsLightbox from "fslightbox-react";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectImages} from "./lightboxSlice";

export function Lightbox({identifier}) {

    const images = useSelector(selectImages)

    // if toggler is updated when lightbox is closed it will open it
    // if toggler is updated when lightbox is opened it will close it
    const [toggler, setToggler] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        if (images && images[identifier] && images[identifier].length > 0) {
            setToggler((toggler) => !toggler);
        }
        setIsLoading(false);
    }, [identifier, images]);

    if (isLoading === true) {
        return <div/>
    }

    let attributes = [];

    if (images[identifier] && images[identifier].length > 0) {
        for (let i = 0; i < images[identifier].length; i++) {

            attributes = [...attributes, {
                decoding: "async"
            }]
        }
    }

    return <FsLightbox
        toggler={toggler}
        type={"image"}
        sources={images[identifier]}
        key={identifier}
        customAttributes={attributes}
    />

}
