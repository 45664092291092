import React, {Fragment, useEffect} from 'react';
import Footer from "../layout/Footer";
import {Pwabanner} from "../features/Pwabanner/Pwabanner";
import {useLocation} from "react-router-dom";
import {pageViewsTracking} from "../app/config";

const AppContainer = (props) => {

    const location = useLocation();

    // Fired on every route change
    useEffect(() => {
        pageViewsTracking();
    }, [location]);

    return (

        <Fragment>
            <div className="outer-wrapper">
                <div className="inner-wrapper d-flex flex-column flex-grow-1 overflow-auto">

                    {props.children}
                </div>

                <Footer/>

            </div>

            <Pwabanner/>

        </Fragment>

    );
}

export default AppContainer;
