import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Content} from "../../components/Content";
import {useDispatch, useSelector} from "react-redux";
import {
    selectActive,
    setActive,
} from "./supplyFiltersSlice";
import {SubmenuTop} from "../../components/Submenu/SubmenuTop";
import {Word} from "../../components/Word";
import {SupplyFilter} from "../../components/Supply/SupplyFilter";

/**
 *
 * @returns {JSX.Element}
 */
export function SupplyFilters({autocompleteHandle, handleSubmit, identifier}) {

    const dispatch = useDispatch();
    const active = useSelector(selectActive);

    return (
        <Content bb={true}>

            <form className="form" onSubmit={handleSubmit}>

                <div className="d-flex align-items-center">

                    <div className={"w-100"}>
                        {autocompleteHandle}
                    </div>

                    <div className="ms-2 filters text-blue">
                        <div className="filters__label d-flex align-items-center"
                             onClick={() => dispatch(setActive(!active))}>
                            <span className="icon me-1">
                                <FontAwesomeIcon icon={faFilter}/>
                            </span>
                            <Word tag={"SUPPLYFILTERS-FILTER"}/>
                        </div>
                    </div>

                </div>

                <div
                    className={"filters__form form notification__submenu" + (active ? " notification__submenu--visible d-block" : "")}>

                    <div className="notification__submenu__inner">

                        <SubmenuTop label={<Word tag={"SUPPLYFILTERS-TITLE"}/>}
                                    icon={<FontAwesomeIcon icon={faFilter}/>}
                                    hide={() => dispatch(setActive(false))}/>

                        <div className="notification__submenu__center mt-3">
                            <div className="container">

                                <SupplyFilter identifier={identifier}/>

                                <div className="form__component form__component--buttons">
                                    <div className="form__element">
                                        <button type="submit"
                                                className="button button--primary button--readmore w-100">
                                            <Word tag={"BUTTON-SUBMIT"}/>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </form>
        </Content>
    );
}
