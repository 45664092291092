import React, {useRef} from "react";
import {SubmenuTop} from "./Submenu/SubmenuTop";
import {useDispatch, useSelector} from "react-redux";
import {selectStatus, setStatus} from "../features/popup/popupSlice";

/**
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export function Submenu(props) {

    const popupStatus = useSelector(selectStatus);
    const dispatch = useDispatch();
    const ref = useRef(null);

    return (
        <div className={"notification__submenu notification__submenu--" + popupStatus} ref={ref}>
            <div className="notification__submenu__inner">
                <SubmenuTop label={props.label} icon={props.icon} forwardRef={ref} hide={() => dispatch(setStatus("hidden"))}/>
                <div className="notification__submenu__center pt-3">
                    <div className="container">
                        <div className="notification__submenu__options">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
