import {createSlice} from '@reduxjs/toolkit';

export const PwabannerSlice = createSlice({
    name: 'pwabanner',
    initialState: {
        status: null
    },
    reducers: {
        setStatus: (state = this.initialState, action) => {
            state.status = action.payload;
        }
    },
});

export const {setStatus} = PwabannerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectStatus = state => state.pwabanner.status;

export default PwabannerSlice.reducer;
