import store from "./store";
import ReactGA from "react-ga4";
import {selectToken, selectUsername, setToken, setUser,} from "../features/user/userSlice";
import API, {IMAGE_BASE} from "../API";
import {setImages, setOpen} from "../features/lightbox/lightboxSlice";
import {Route, useLocation, useNavigate, useParams} from "react-router-dom";
import React from "react";
import loadImage from "blueimp-load-image"

export const PAGE_TITLE_BASE = " | Varb app";


let isBlocked = false;

const AppHelpers = {

    /**
     *
     * @returns {boolean}
     */
    checkLogin: () => {

        let state;
        state = store.getState();

        if (localStorage.getItem("AUTH-USERNAME")
            && localStorage.getItem("AUTH-TOKEN")
            && selectToken(state) === "") {

            AppHelpers.setUserFromStorage();

            state = store.getState();

        }

        return selectToken(state) !== "" && selectUsername(state) !== "";

    },

    setUserFromStorage: () => {
        const dispatch = store.dispatch;

        dispatch(setUser(localStorage.getItem("AUTH-USERNAME")));
        dispatch(setToken(localStorage.getItem("AUTH-TOKEN")));
    },

    /**
     *
     * @param file
     * @param type
     * @returns {Promise<unknown>}
     */
    resizeImage: ({file}) => loadImage(file,  { canvas: true, meta: true, orientation: true, imageSmoothingEnabled: false, maxWidth: 1920, maxHeight: 1080 })
        .then(function (data) {
            // if (!data.imageHead) throw new Error('Could not parse image metadata')
            return new Promise(function (resolve) {
                data.image.toBlob(function (blob) {
                    data.blob = blob
                    resolve(data)
                }, file.type)
            })
        })
        .catch(function (err) {
            console.error(err)
        }),


    /**
     *
     * @param supplyId
     */
    fetchSupplyImages: (supplyId) => {

        if (!supplyId) {
            throw new Error("Parameter supplyId is required");
        }

        const dispatch = store.dispatch;

        API.fetchImages(supplyId)
            .then((res) => {

                const sourceSet = res.data.images;
                if (!sourceSet || sourceSet.length < 1) {
                    return false;
                }

                const sources = sourceSet.map((slide) => (slide.uri ?? IMAGE_BASE + "/guid/") + "/type/preview-existing/directive");
                const large = sources.map((slide) => slide + "/scalearwithin-600x600");

                dispatch(setImages({identifier: supplyId, items: large}))
                dispatch(setOpen(true));

            });

    },

    pageViewsTracking: () => {
        if (process.env.NODE_ENV !== "production" || process.env.REACT_APP_ENV !== "production") return false;
        ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});
    },

    entityToChar: str => {
        if (typeof str !== 'string' || !str instanceof String) return str;

        const textarea = document.createElement('textarea');
        textarea.innerHTML = str;
        return textarea.value;
    },

    setIsBlocked: (v) => {
        isBlocked = v;
    },

    getIsBlocked: () => isBlocked

}

export const {
    checkLogin,
    setUserFromStorage,
    resizeImage,
    fetchSupplyImages,
    pageViewsTracking,
    entityToChar,
    setIsBlocked,
    getIsBlocked
} = AppHelpers;

/**
 *
 * @param children
 * @param basePath
 * @param props
 * @return {*}
 */
export const mapRoutes = (children, basePath, props) => children.map((route) => {

    const path = basePath + route.path;
    const Component = route.component;
    let routeComponent = Component && <Route path={path} element={<Component {...props}/>}/>;

    if (!route.component && route.redirect) {
        routeComponent = <Route path={path} element={route.redirect}/>
    }

    if (!route.children || route.children.length < 1) {
        return routeComponent;
    }

    return <Route path={path}>
        {mapRoutes(route.children, path, props)}
        {routeComponent}
    </Route>

})

/**
 *
 * @param Component
 * @return {function(*)}
 */
export const withNavigate = Component => props => {
    let navigate = useNavigate(), location = useLocation();
    let params = useParams();

    return <Component
        navigate={navigate}
        location={location}
        params={params}
        {...props}
    />;
}
