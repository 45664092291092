import {createSlice} from '@reduxjs/toolkit';

export const SupplyFilterSlice = createSlice({
    name: 'supplyFilters',
    initialState: {
        identifier: "",
        active: false,
        sortLabels: {
            asc: "Oplopend",
            desc: "Aflopend"
        },
        sortTypes: [
            {
                key: "relevance",
                label: "Relevantie",
                contexts: ["IDENTITY", "NONE"]
            },
            {
                key: "date",
                label: "Datum",
                contexts: ["IDENTITY"]
            },
            {
                key: "price",
                label: "Prijs",
                types: ['asc', 'desc'],
                contexts: ["IDENTITY", "NONE"]
            },
            {
                key: "quantity",
                label: "Aantal",
                types: ['asc', 'desc'],
                contexts: ["IDENTITY", "NONE"]
            },
        ],
        filters: {}
    },
    reducers: {
        addFilter: (state = this.initialState, action) => {
            const {identifier} = action.payload;

            state.filters = {
                ...state.filters,
                [identifier]: {
                    terms: {
                        sort: "relevance",
                    }
                }
            }
        },
        setTerms: (state = this.initialState, action) => {
            const {identifier, ...terms} = action.payload;
            state.filters[identifier].terms = terms;
        },
        setTerm: (state = this.initialState, action) => {
            const {identifier, name, value} = action.payload;
            state.filters[identifier].terms[name] = value;
        },
        setActive: (state = this.initialState, action) => {
            state.active = action.payload;
        },
    },
});

export const {addFilter, setTerms, setTerm, setActive} = SupplyFilterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectFilter = (state, identifier) => state.supplyFilters.filters[identifier];
export const selectActive = state => state.supplyFilters.active;
export const selectSortTypes = state => state.supplyFilters.sortTypes;
export const selectSortLabels = state => state.supplyFilters.sortLabels;
export const selectTerms = (state, identifier) => {
    if (state.supplyFilters.filters[identifier]
        && typeof state.supplyFilters.filters[identifier].terms !== 'undefined') {

        return state.supplyFilters.filters[identifier].terms;
    }

    return [];

}
export const selectTerm = (state, identifier, term) => {

    if (state.supplyFilters.filters[identifier]
        && typeof state.supplyFilters.filters[identifier].terms !== 'undefined'
        && typeof state.supplyFilters.filters[identifier].terms[term] !== 'undefined') {

        return state.supplyFilters.filters[identifier].terms[term];
    }
};

export default SupplyFilterSlice.reducer;
