import React from "react";
import Supply from "../routes/Supply";
import SupplyEditFast from "../routes/Supply/SupplyEditFast";
import Search from "../routes/Search";
import SearchResults from "../routes/Search/SearchResults";
import {SearchDetails} from "../routes/Search/SearchDetails";
import {SearchRequest} from "../routes/Search/SearchRequest";
import {AuthForgot} from "../routes/Auth/AuthForgot";
import AuthLogout from "../routes/Auth/AuthLogout";
import {Navigate} from "react-router-dom";
import {AppSettings} from "../routes/AppSettings";
import {Page500} from "../routes/500";
import AuthLogin from "../routes/Auth/AuthLogin";
import {Page404} from "../routes/404";
import {SupplyGallery} from "../routes/Supply/SupplyGallery";
import {SupplyEdit} from "../routes/Supply/SupplyEdit";

export const routes = [
    {
        path: "/eigen-aanbod",
        pageTitle: "Eigen aanbod",
        component: Supply,
        children: [
            {
                path: "/toevoegen",
                pageTitle: "Aanbod toevoegen",
                component: SupplyEdit
            },
            {
                path: "/:supplyId/bewerken",
                pageTitle: "Aanbod bewerken",
                component: SupplyEdit
            },
            {
                path: "/:supplyId/snel-bewerken",
                pageTitle: "Aanbod snel bewerken",
                component: SupplyEditFast
            },
            {
                path: "/:supplyId/gallerij",
                pageTitle: "Foto's bewerken",
                component: SupplyGallery
            }
        ]
    },
    {
        path: "/zoeken",
        pageTitle: "Aanbod zoeken",
        component: Search,
        children: [
            {
                path: "/resultaten",
                pageTitle: "Zoekresultaten",
                component: SearchResults,
                children: [
                    {
                        path: "/:supplyId",
                        pageTitle: "Resultaat",
                        component: SearchDetails,
                        children: [
                            {
                                path: "/aanvragen/:requestType",
                                pageTitle: "Resultaat",
                                component: SearchRequest
                            }
                        ]
                    },
                ]
            },
        ]
    },
    {
        path: "/auth",
        pageTitle: "Autorisatie",
        redirect: <Navigate to={"/"}/>,
        children: [
            {
                path: "/forgot",
                pageTitle: "Wachtwoord vergeten",
                component: AuthForgot
            },
            {
                path: "/logout",
                pageTitle: "Uitloggen",
                component: AuthLogout
            },
            {
                path: "/*",
                pageTitle: "Inloggen",
                redirect: <Navigate to={"/"}/>
            },
        ]
    },
    {
        path: "/login",
        pageTitle: "Inloggen",
        redirect: <Navigate to={"/"}/>
    },
    {
        path: "/app-settings",
        pageTitle: "App info",
        component: AppSettings
    },
    {
        path: "/500",
        pageTitle: "Er is technische fout opgetreden",
        component: Page500
    },
    {
        path: "/404",
        pageTitle: "Pagina niet gevonden",
        component: Page404
    },
    {
        path: "/",
        pageTitle: "Inloggen",
        component: AuthLogin
    },
]
