import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {Word} from "../components/Word";

function Footer() {

    const itemClass = ({isActive}) => "footer__nav__item footer__item py-2" + (isActive ? " footer__item--current text-primary border-primary" : "");

    return (
        <footer className="footer bg-white">

            <div className="footer__nav d-flex">

                {/*<NavLink className="footer__nav__item footer__item" to="/home"*/}
                {/*         activeClassName="footer__item--current">*/}
                {/*    <div className="footer__item__icon">*/}
                {/*        <i className="icon icon-house"></i>*/}
                {/*    </div>*/}
                {/*    <div className="footer__item__label">*/}
                {/*        Home*/}
                {/*    </div>*/}
                {/*</NavLink>*/}

                <NavLink className={itemClass} to="/eigen-aanbod">
                    <div className="footer__item__icon">
                        <i className="icon icon-leaf"></i>
                    </div>
                    <div className="footer__item__label">
                        <Word tag={"ROUTES-LINK-SUPPLY"}/>
                    </div>
                </NavLink>

                <NavLink className={itemClass} to="/zoeken">
                    <div className="footer__item__icon">
                        <i className="icon icon-search"></i>
                    </div>
                    <div className="footer__item__label">
                        <Word tag={"ROUTES-LINK-SEARCH"}/>
                    </div>
                </NavLink>

                <NavLink className={itemClass} to="/app-settings">
                    <div className="footer__item__icon">
                        <FontAwesomeIcon icon={faEllipsisH}/>
                    </div>
                    <div className="footer__item__label">
                        <Word tag={"ROUTES-LINK-APPSETTINGS"}/>
                    </div>
                </NavLink>

            </div>
        </footer>
    );
}

export default Footer;
