import Alert from "../../components/Alert";
import {useDispatch, useSelector} from "react-redux";
import {clearAlertsAsync, selectAlerts} from "./alertsSlice";
import {useEffect} from "react";
import {Word} from "../../components/Word";

export function Alerts({identifier, types, disableClear = false, timeOut = 3500}) {

    const dispatch = useDispatch();
    const alertItems = useSelector(selectAlerts);

    const items = alertItems[identifier] || [];

    const alerts = items.length > 0 && items.map((alert, index) => {

        const alertItem = {...alert};
        const mess = alertItem.message ?? "";

        if (typeof mess === "string") {
            alertItem.message = <Word tag={mess}/>
        }

        const alertElm = <Alert key={"alert-" + identifier + "-" + index} {...alertItem}/>;

        if (types && types.length > 0 && types.includes(alertItem.type)) {
            return alertElm;
        }

        if (!types || types.length < 1) {
            return alertElm;
        }

        return '';

    });

    useEffect(() => {

        if (alerts && alerts.length > 0 && disableClear !== true) {
            dispatch(clearAlertsAsync({
                identifier: identifier,
                timeOut: timeOut
            }));
        }
    }, [alerts, disableClear, dispatch, identifier]);

    return alerts;

}
