import * as React from 'react';
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import nl from "date-fns/locale/nl"; // the locale you want
import {subYears, addYears} from "date-fns";
import {format, parse} from 'date-fns'
import {useFormErrorClass} from "../../FormFromArray";
import {ThemeProvider} from "@mui/material";
import {theme} from "../../../app/mui-themes"
import {Word} from "../../Word";


/**
 *
 * @param attr
 * @param hasError
 * @param field
 * @param formHandlers
 * @return {JSX.Element}
 * @constructor
 */
export function FormElementDatepicker({attr, hasError, field, formHandlers}) {
    const className = useFormErrorClass(attr, hasError);
    const {getValues, setValue} = formHandlers;

    return <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
            <MobileDatePicker
                minDate={subYears(new Date(), 15)}
                maxDate={addYears(new Date(), 6)}
                value={getValues(field.name)}
                onChange={(newValue) => {
                    let datetime = newValue;
                    if (newValue) {
                        datetime = format(new Date(newValue), 'yyyy-MM-dd');
                    }
                    setValue(field.name, datetime)
                }}
                showTodayButton={true}
                inputFormat={"dd-MM-yyyy"}
                toolbarFormat={"EEEEEE, dd MMM yyyy"}
                toolbarTitle={<Word tag={"DATEPICKER-SELECT-DATE"}/>}
                cancelText={<Word tag={"BUTTON-CANCEL"}/>}
                todayText={<Word tag={"DATEPICKER-TODAY"}/>}
                renderInput={params => <TextField {...params} className={className} variant="standard"/>}
            />
        </LocalizationProvider>

    </ThemeProvider>

}
