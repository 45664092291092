import React, {Fragment, useEffect, useRef, useState} from 'react';
import {HeaderDefault} from "../../layout/Header";
import API from "../../API";
import {Link, useNavigate} from "react-router-dom";
import Alert from "../../components/Alert";
import {Content} from "../../components/Content";
import {FormComponent} from "../../components/Form/FormComponent";
import {FormElementInput} from "../../components/Form/Element/FormElementInput";
import {useDispatch, useSelector} from "react-redux";
import {addAlert} from "../../features/alerts/alertsSlice";
import {PAGE_TITLE_BASE, pageViewsTracking} from "../../app/config";
import {Word} from "../../components/Word";
import {selectWords} from "../../features/translate/translateSlice";
import {Pwabanner} from "../../features/Pwabanner/Pwabanner";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AuthForgot(props) {

    const [username, setUsername] = useState('');
    const [errors, setErrors] = useState([]);
    const ref = useRef(null);
    const words = useSelector(selectWords)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Wachtwoord vergeten" + PAGE_TITLE_BASE
        pageViewsTracking();
    }, [])

    /**
     *
     * @param event
     */
    const handleSubmit = (event) => {
        event.preventDefault();

        if (username === "") {
            setErrors([{
                type: "danger",
                message: "Gebruikersnaam ontbreekt"
            }]);

            return false;
        }

        API.userForgot(username)
            .then((res) => {
                if (res.data.status !== "OK") {
                    setErrors([{
                        type: "danger",
                        message: res.data.displayMessage
                    }]);
                } else {

                    dispatch(addAlert({
                        identifier: "auth-alerts",
                        type: "success",
                        message: res.data.message
                    }));

                    navigate("/");

                }
            })
            .catch((error) => {
                console.error(error);
            });

    }

    return (

        <Fragment>

            <div className="outer-wrapper">

                <div className="inner-wrapper">

                    <HeaderDefault/>

                    <Content>
                        <h1 className="c-secondary mb-0">
                            <Word tag={"HEADER-FORGOT-TITLE"}/>
                        </h1>
                    </Content>
                    <Content>

                        <p><Word tag={"AUTH-FORGOT-DESCR"}/></p>

                        {errors.map((item, index) => <Alert key={"error-" + index} type={item.type} message={item.message}/>)}

                        <form onSubmit={handleSubmit}>

                            <FormComponent label={<Word tag={"LABEL-AUTH-FORGOT-EMAIL"}/>} element={
                                <FormElementInput required={true} attr={{
                                    type: "email",
                                    onChange: () => setUsername(ref.current.value),
                                    ref: ref,
                                    defaultValue: username,
                                    placeholder: words["PWA-PLACEHOLDER-AUTH-FORGOT-EMAIL"]
                                }}/>
                            }
                            />

                            <div
                                className="form__component form__component--buttons form__component--fieldset">
                                <div className="form__component__column">
                                    <div className="form__element">
                                        <button type={"submit"}
                                                className="button button--primary button--readmore">
                                            <Word tag={"BUTTON-SEND"}/>
                                        </button>
                                    </div>
                                </div>
                                <div className="form__component__column mt-2 mt-md-0">
                                    <div className="form__element">
                                        <Link to={"/auth/login"} className="read-more read-more--reverse">
                                            <Word tag={"AUTH-FORGOT-CANCEL"}/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </Content>

                </div>


            </div>

            <Pwabanner />

        </Fragment>
    )
}
