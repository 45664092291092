import React from "react";
import {useFormErrorClass} from "../../FormFromArray";

/**
 *
 * @param attr
 * @param error
 * @param content
 * @return {JSX.Element}
 * @constructor
 */
export function FormElementCheckbox({attr, hasError, content, field}) {

    const className = useFormErrorClass(attr, hasError);

    return <React.Fragment>
        <input type="checkbox" value={1} className={className} {...attr} {...field}/>
        <label htmlFor={attr.id ?? ""}>{content}</label>
    </React.Fragment>

}
