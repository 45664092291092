import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH, faTimes} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export function SubmenuTop(props) {

    let {icon, label, forwardRef} = props;

    const hideSubmenu = (e) => {
        const {target} = e;

        if (forwardRef && forwardRef.current && target) {
            forwardRef.current.classList.remove('notification__submenu--visible');
        }
    }

    if (!icon) {
        icon = <FontAwesomeIcon icon={faEllipsisH}/>;
    }
    if (!label) {
        label = "Meer";
    }

    return <div className="notification__submenu__top py-2 text-primary bg-light border-bottom">
        <div className="container">
            <div className={"d-flex justify-content-between"}>
                <div>
                    <span className="icon me-1">
                        {icon}
                    </span>
                    {label}
                </div>
                <div className={"notification__submenu__close"} style={{cursor: "pointer"}} onClick={props.hide ?? hideSubmenu}>
                    <span className="icon">
                        <FontAwesomeIcon icon={faTimes}/>
                    </span>
                </div>
            </div>
        </div>
    </div>;

}