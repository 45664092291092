export function FormElementTitle(props) {

    const html = props.content;

    const htmlDecode = (input) => {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    return <h3 className={"mb-0"} dangerouslySetInnerHTML={{__html: htmlDecode(html)}}/>

}
