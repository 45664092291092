import React, {useEffect, useState, Fragment} from "react";
import AppContainer from "../../components/AppContainer";
import {useNavigate, useParams} from "react-router-dom";
import {HeaderReturn, HeaderDefault} from "../../layout/Header";
import API from "../../API";
import Loader from "../../components/Loader";
import Carousel from "../../components/Carousel";
import {Content} from "../../components/Content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCamera, faEnvelope,
    faPhone,
    faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import {SupplyDetails} from "../../components/Supply/SupplyDetails";
import {Alerts} from "../../features/alerts/Alerts";
import {PAGE_TITLE_BASE} from "../../app/config";
import {Identity} from "../../components/Identity";

export function SearchDetails() {

    let {supplyId} = useParams();
    const [isLoading, setIsLoading] = useState(true),
        [data, setData] = useState(null),
        navigate = useNavigate();

    useEffect(() => {

        document.title = "Details zoeken" + PAGE_TITLE_BASE

        setIsLoading(true);

        const fetchData = async () => {
            await API.fetchSupply(supplyId)
                .then((res) => {
                    if (res && res.data.status === 'OK') {
                        setData(res.data);
                    }
                })
        }

        fetchData();

        setIsLoading(false);

    }, [supplyId])

    if (isLoading === true || !data) {
        return (
            <Fragment>

                <Identity/>

                <AppContainer>

                    <HeaderDefault/>

                    <div className={"content"}>
                        <div className="container">
                            <Loader/>
                        </div>
                    </div>
                </AppContainer>

            </Fragment>
        )
    }

    const {plant, owner, images} = data;

    let ownerAddressParts = [];
    let ownerZipCode = "";

    if (owner && owner.ownerOfficeAddress) {
        ownerAddressParts = owner.ownerOfficeAddress.split(", ");
        ownerZipCode = ownerAddressParts[1].match(new RegExp('([0-9]+)|([a-zA-Z]+)', 'g'));
    }

    let headerAction = <div className={"text-end"} style={{width: "100px"}}>
        <button className={"btn btn-primary"} onClick={() => handleRequest("BUY")}>
            <span className="icon icon-handshake"/>
        </button>
        <button className={"btn btn-primary ms-1"} onClick={() => handleRequest("INFO")}>
            <span className="icon icon-price-request"/>
        </button>
    </div>;

    if (parseInt(data.owned) === 1) {
        headerAction = null;
    }

    const handleRequest = requestType => {
        navigate("/zoeken/resultaten/" + supplyId + "/aanvragen/" + requestType.toLowerCase());
    }

    let imageContainer;

    if (images && images.length > 0) {
        imageContainer = <Content bb={true}>
            <Carousel slides={images} settings={{
                slidesToShow: 3,
                centerMode: false,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 3
                        }
                    }
                ]
            }}/>
        </Content>;
    } else {
        imageContainer = <Content bb={true}>
            <p>Dit aanbod bevat huidig (nog) geen foto's.</p>
            {(parseInt(data.owned) !== 1 ?
                <button className={"btn btn-outline-primary"} type={"button"} onClick={() => handleRequest("IMAGE")}>
                    <span className={"me-1"}><FontAwesomeIcon icon={faCamera}/></span>
                    Foto aanvragen
                </button>
                : "")}
        </Content>
    }

    return (
        <Fragment>

            <Identity/>

            <AppContainer>

                <HeaderReturn
                    action={headerAction}
                    handleOnClick={() => navigate("/zoeken/resultaten")}
                />

                <Alerts identifier={"searchRequestAlerts"}/>

                <Content bb={true}>
                    <div className="results__overview__list__item__title">{plant.plantName}</div>

                    <div className={"d-flex"}>
                        <div>
                            <p className={"mb-0"}>
                                {owner.ownerName}<br/>
                                {ownerAddressParts[0]}<br/>
                                {ownerZipCode[0] + " " + ownerZipCode[1] + " " + ownerAddressParts[2]}<br/>
                                {"Nederland"}
                            </p>

                        </div>
                        <div className={"ms-auto text-end d-flex flex-column"}>
                            <div className={"mb-auto"}>
                                {(parseInt(data.hasRelation) === 1 || parseInt(data.owned) === 1
                                        ? <button type="button"
                                                  className="text-blue text-center border-0 bg-transparent"
                                                  disabled={true}>
                                            <FontAwesomeIcon icon={faUserCheck} fixedWidth={true}/>
                                        </button>
                                        : <button type="button"
                                                  className="btn btn-outline-primary"
                                                  onClick={() => handleRequest("RELATION")}>
                                            <span className="icon icon-relation-request"/>
                                        </button>
                                )}

                            </div>
                            <div className="d-flex flex-nowrap">
                                <button type="button" style={{width: "45px", height: "35px"}}
                                        className="btn btn-outline-primary ms-1"
                                        onClick={() => window.open('mailto:' + owner.ownerEmailAddress)}>
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                </button>
                                <button type="button" style={{width: "45px", height: "35px"}}
                                        className="btn btn-outline-primary ms-1"
                                        onClick={() => window.open('tel:' + owner.ownerNumberPhone1)}>
                                    <FontAwesomeIcon icon={faPhone}/>
                                </button>
                            </div>

                        </div>
                    </div>

                </Content>

                {imageContainer}

                <SupplyDetails data={data}/>

            </AppContainer>
        </Fragment>
    );

}
