import React, {useEffect, useState} from "react";
import {useFormErrorClass} from "../../FormFromArray";
import Autocomplete from "../../../features/autocomplete/Autocomplete";
import {Word} from "../../Word";

/**
 *
 * @param attr
 * @param hasError
 * @param field
 * @param clearErrors
 * @param setValue
 * @return {JSX.Element}
 * @constructor
 */
export function FormElementPlantSearch({attr, hasError, field, formHandlers: {clearErrors, setValue, getValues}}) {

    const className = useFormErrorClass(attr, hasError);
    const [plant, setPlant] = useState([]);
    const storedValue = getValues(attr.name);

    useEffect(() => {
        if (storedValue) {
            setPlant(storedValue);
        }
    }, [storedValue])

    const {onChange, onBlur, ...inputProps} = field;

    const handleChange = (value) => {
        setPlant(value);
        if (value && value.length > 0) {
            setValue(attr.name, value);
            clearErrors(attr.name);
        }
    }

    const onClear = () => {
        setPlant([]);
        setValue(attr.name, null);
    }

    let plantInfo = null;
    if (plant && plant.length > 0) {
        plantInfo = <div className={"bg-light p-3 mt-1"}>
            <h4 className={"mt-0"}>Gekozen plant:</h4>
            <p className={"mb-0"}>
                <strong><Word tag={"LABEL-SUPPLY-EDIT-PLANTNUMBER"}/>: </strong>{plant[0].plantNumber}<br/>
                <strong><Word tag={"LABEL-SUPPLY-EDIT-PLANTCODE"}/>: </strong>{plant[0].plantCode}<br/>
                <strong><Word tag={"LABEL-SUPPLY-EDIT-FULLNAME"}/>: </strong>{plant[0].display}
            </p>
        </div>
    }

    return (<div style={{width: "100%"}}>
            <Autocomplete context={"PLANT"}
                          inputProps={{...inputProps, className: className}}
                          defaultSelected={storedValue ?? []}
                          onChange={handleChange}
                          onClear={onClear}
                          onBlur={onBlur}
            />
            {plantInfo}
        </div>
    )

}
