import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState: {
        items: {}
    },
    reducers: {
        addAlert: (state, action) => {
            const {identifier} = action.payload;

            if (!identifier) {
                throw new Error("You cannot add an alert without prop 'identifier'");
            }

            state.items = {
                ...state.items,
                [identifier]: (state.items[identifier] && state.items[identifier].length > 0
                        ? [...state.items[identifier], ...[action.payload]]
                        : [action.payload]
                )
            }

        },

        clearAlerts: (state, action) => {
            state.items = {};
        },

        clearAlertsByIdentifier: (state, action) => {

            if (!action.payload) {
                throw new Error("You cannot clear alerts without prop 'identifier'");
            }

            delete state.items[action.payload];

        }

    },
});

export const {addAlert, clearAlertsByIdentifier, clearAlerts} = alertsSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const clearAlertsAsync = (identifier) => (dispatch, getState) => {
//     setTimeout(() => {
//         dispatch(clearAlertsByIdentifier(identifier));
//     }, (3500));
// };

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const clearAlertsAsync = createAsyncThunk(
    'alerts/clearAlerts',
    async (props, {dispatch}) => {

        let identifier = "", timeOut = 3500;

        if (typeof props === 'string') {
            identifier = props;
        } else if (typeof props === "object") {
            identifier = props.identifier;
            if (props.timeOut) {
                timeOut = parseInt(props.timeOut);
            }
        }

        if (identifier && timeOut) {
            setTimeout(() => dispatch(clearAlertsByIdentifier(identifier)), timeOut);
        }

    }
);

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectAlerts = (state) => state.alerts.items;

export default alertsSlice.reducer;
