import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import Autocomplete from "../features/autocomplete/Autocomplete";

import AppContainer from "../components/AppContainer";
import {HeaderDefault} from "../layout/Header";
import {
    setSelected
} from "../features/autocomplete/autocompleteSlice";
import {Content} from "../components/Content";
import {PAGE_TITLE_BASE, withNavigate} from "../app/config";
import {Identity} from "../components/Identity";
import {Word} from "../components/Word";
import {selectWord} from "../features/translate/translateSlice";
import {SupplyFilter} from "../components/Supply/SupplyFilter";
import {setList} from "../features/list/listSlice";

/**
 * Supply class
 */
class Search extends Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);

        this.identifier = "NONE";
        this.autocompleteRef = React.createRef();
    }

    /**
     *
     * @param s
     * @returns {boolean}
     */
    handleSelect = s => {

        if (!s || s.length < 1) {
            return false;
        }

        this.autocompleteRef.current.blur();

        this.props.dispatch(setSelected({
            identifier: this.identifier,
            value: s
        }));

        this.props.dispatch(setList({
            identifier: this.identifier,
            triggerRefresh: true
        }));

        this.props.navigate(this.props.location.pathname + "/resultaten");

    }

    componentDidMount() {
        const routeTitle = selectWord(this.props, "ROUTE-SEARCH-TITLE");
        document.title = routeTitle + PAGE_TITLE_BASE;
    }

    render() {

        const forwardedRef = this.autocompleteRef;

        const autocompleteHandle = <Autocomplete
            forwardedRef={forwardedRef}
            context={this.identifier}
            hasIcon={true}
            onChange={this.handleSelect}
            onKeyDown={({keyCode}) => {

                if (keyCode === 13) {

                    this.props.dispatch(setSelected({
                        identifier: this.identifier,
                        value: [{
                            repeatSearchQuery: forwardedRef.current.inputNode.value
                        }]
                    }));

                    forwardedRef.current.blur();
                    this.props.navigate("/zoeken/resultaten")

                }
            }}/>


        return (

            <Fragment>

                <Identity/>

                <AppContainer>

                    <HeaderDefault/>

                    <Content>
                        <h1 className="text-primary mb-0"><Word tag={"ROUTE-SEARCH-TITLE"} /></h1>
                    </Content>

                    <Content>
                        <p><Word tag={"SEARCH-CONTENT"}/></p>
                    </Content>

                    <Content>{autocompleteHandle}</Content>

                    <Content>
                        <SupplyFilter identifier={this.identifier}/>
                    </Content>

                </AppContainer>

            </Fragment>


        );
    }

}

/**
 *
 * @param state
 * @return {{autocomplete, user, supplyFilters: *, translate}}
 * @constructor
 */
const MapStateToProps = (state) => ({
    user: state.user,
    autocomplete: state.autocomplete,
    supplyFilters: state.supplyFilters,
    translate: state.translate
});

export default connect(MapStateToProps)(withNavigate(Search));
