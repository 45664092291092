import React, {Fragment} from 'react';
import AppContainer from "../components/AppContainer";
import {HeaderDefault} from "../layout/Header";
import {Content} from "../components/Content";
import {Identity} from "../components/Identity";
import {Word} from "../components/Word";

export function Page404() {
    return (
        <Fragment>

            <Identity/>

            <AppContainer>

                <HeaderDefault/>

                <Content>
                    <h1 className="c-secondary"><Word tag={"PAGE-NOT-FOUND"}/></h1>
                </Content>

            </AppContainer>

        </Fragment>
    )

}
