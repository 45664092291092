import React, {useCallback, useEffect, useState} from "react";
import {useForm, Controller, FormProvider} from "react-hook-form";
import {FormComponent} from "./Form/FormComponent";
import {FormElementSubmit} from "./Form/Element/FormElementSubmit";
import {FormElementSelect} from "./Form/Element/FormElementSelect";
import {FormElementInput} from "./Form/Element/FormElementInput";
import {FormElementTextarea} from "./Form/Element/FormElementTextarea";
import {FormElementCheckbox} from "./Form/Element/FormElementCheckbox";
import {FormElementRadio} from "./Form/Element/FormElementRadio";
import {FormElementTitle} from "./Form/Element/FormElementTitle";
import {FormElementHtml} from "./Form/Element/FormElementHtml";
import {Word} from "./Word";
import {FormElementError} from "./Form/Element/FormElementError";
import {FormElement} from "./Form/FormElement";
import {FormElementPlantSearch} from "./Form/Element/FormElementPlantSearch";
import {FormElementDatepicker} from "./Form/Element/FormElementDatepicker";
import axios from "axios";
import API, {API_URL} from "../API";
import {Alerts} from "../features/alerts/Alerts";
import {useDispatch} from "react-redux";
import {addAlert, clearAlerts} from "../features/alerts/alertsSlice";
import Loader from "./Loader";

/**
 *
 * @param props
 * @return {JSX.Element|string}
 * @constructor
 */
export function FormFromArray(props) {

    const {
        handlers,
        fields,
        dependencies,
        dynamicFields,
        rules,
        prefill = null,
        buttonLabel,
        customSubmit,
        formProps,
        onValueChange = null
    } = props

    const [isLoaded, setIsLoaded] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [dynamicOptions, setDynamicOptions] = useState({});
    const formRef = React.useRef(null);
    const dispatch = useDispatch();

    const methods = useForm({shouldFocusError: false, reValidateMode: "onBlur"});

    const {
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: {isSubmitted}
    } = methods;

    let dependenciesList = dependencies;

    /*
    Collect watched fields by dependencies
     */
    let watchedFields = [];
    if (dependencies) {
        for (const key in dependencies) {

            const settings = dependencies[key];
            const parentFieldName = settings.dependantBy;

            if (!watchedFields.includes(parentFieldName)) {
                watchedFields = [...watchedFields, parentFieldName];
            }

            if (key.includes("[[VALUE]]")) {
                const parentValue = getValues(parentFieldName);

                if (parentValue && parentValue.toString().length > 0) {

                    const dynamicName = key.replace("[[VALUE]]", parentValue);
                    const fieldName = getFieldNameByKey(dynamicName ?? "")

                    dependenciesList = {
                        ...dependenciesList,
                        [fieldName]: settings
                    }

                }
            }

        }

    }

    let fieldsRules = {};

    if (rules) {
        Array.from(rules).map((rule) => {

            const chainedFields = rule.fields;
            const ruleIdentifier = rule.type;

            if (chainedFields) {

                Array.from(chainedFields).map((fieldName) => {
                    const name = getFieldNameByKey(fieldName);

                    if (!watchedFields.includes(name)) {
                        watchedFields = [...watchedFields, name];
                    }

                    fieldsRules = {
                        ...fieldsRules,
                        [name]: {
                            ...fieldsRules[name] ?? {},
                            [ruleIdentifier]: (v) => applyRule({
                                type: ruleIdentifier,
                                formHandlers: methods,
                                chainedFields,
                                v
                            })
                        }
                    }
                });

            }
        })
    }

    if (watchedFields.length > 0) {
        watch(watchedFields);
    }

    const castToTypes = useCallback(({formFields, data, methods}) => castFieldToType(data, formFields, methods), []);

    const onSubmit = data => {

        dispatch(clearAlerts);

        castToTypes({data, formFields, methods});
        const preparedFormData = methods.getValues();

        if (handlers && handlers.onSubmit) {
            handlers.onSubmit(preparedFormData);
        }
    }

    const onError = handlers.onError !== false
        ? (errors, e) => {

            console.log(errors)

            if (errors) {
                dispatch(addAlert({
                    type: "danger",
                    message: "FORM-ERROR-GENERALMESSAGE",
                    identifier: "formAlerts"
                }))

                formRef.current?.scrollIntoView();
            }

            if (handlers.isSavingHandler) {
                handlers.isSavingHandler(false);
            }

        }
        : false

    useEffect(() => {
        if (isSubmitted === false) {
            setFormFields(fields)

            if (prefill) {
                reset(prefill);
            }
            setIsLoaded(true);
        }
    }, [fields, setFormFields, setIsLoaded, reset, prefill, isSubmitted])

    if (isLoaded === false) {
        return <Loader/>
    }

    let buttonProps = {}

    if (buttonLabel) {
        buttonProps = {...buttonProps, buttonLabel: buttonLabel}
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit, onError)} noValidate={true} ref={formRef} {...formProps}>

                <Alerts identifier={"formAlerts"}/>

                {mapFields(formFields, null, {
                    ...methods,
                    ...{
                        dynamicFields,
                        dynamicOptions,
                        setDynamicOptions,
                        dependenciesList,
                        rules: fieldsRules,
                        onValueChange
                    }
                })}

                {!customSubmit
                    ? <FormComponent element={<FormElementSubmit {...buttonProps}/>}/>
                    : customSubmit
                }

            </form>
        </FormProvider>
    )
}

export const formElementFactory = {
    select: FormElementSelect,
    text: FormElementInput,
    search: FormElementInput,
    email: FormElementInput,
    textarea: FormElementTextarea,
    checkbox: FormElementCheckbox,
    radio: FormElementRadio,
    title: FormElementTitle,
    html: FormElementHtml,
    password: FormElementInput,
    plantSearch: FormElementPlantSearch,
    bootstrapdatetime: FormElementDatepicker,
    hidden: FormElementInput
}

/**
 *
 * @param string
 * @return {*}
 */
export const getFieldNameByKey = string => {
    let str = string.replace("[", ".").replace("]", "");
    if (str[str.length - 1] === ".")
        str = str.slice(0, -1);

    return str;
}


export const castFieldToType = (submittedData, formFields, methods) => {

    Array.from(formFields).forEach((field) => {

        /*
        Check if it has dataType
         */
        const dataType = field.dataType ?? null;
        const fieldName = getFieldNameByKey(field.attributes.name ?? ""); //TODO: Nested like io and i1.
        const value = submittedData[fieldName];

        if (dataType && value) {
            switch (dataType) {
                case "INT":
                case "INTEGER":
                case "MIN-NUMERIC":
                    methods.setValue(fieldName, parseInt(value));
                    break;
                case "FLOAT":
                    const float = parseFloat(value.replace(",", "."));
                    methods.setValue(fieldName, float.toFixed(2));
                    break;
            }
        } else if (dataType) {
            methods.setValue(fieldName, null);
        }

        if (field.subElements && field.subElements.length > 0) {
            castFieldToType(submittedData, field.subElements, methods); // recursively check
        }

    })

    // return submittedData;

}


const isSubElementVisible = (fields, allValues, dependencies) => {

    let rt = false;

    /*
    Great another recursive
     */
    Array.from(fields).forEach((field) => {
        const {name} = field.attributes;
        const fieldName = getFieldNameByKey(name ?? "");

        if (field.hidden !== undefined && dependencies[fieldName]) {

            /*
            Check if parent already had a value
             */
            const hidden = isFieldHidden(fieldName, dependencies, allValues)

            if (hidden === false) {
                rt = true;
                return false; // end mapping
            }

            if (field.subElements.length > 0 && isSubElementVisible(field.subElements, allValues, dependencies) !== false) {
                rt = true;
                return false; // end mapping
            }

        }
    });

    return rt;
}

/**
 *
 * @param fieldName
 * @param dependencies
 * @param allValues
 * @return {boolean}
 */
const isFieldHidden = (fieldName, dependencies, allValues) => {

    /*
    Check if parent already had a value
     */
    const parentName = dependencies[fieldName].dependantBy;
    let parentValue = allValues[parentName] ?? "";

    if (dependencies[fieldName].setting) {
        if (dependencies[fieldName].setting.equalsTo) {
            const expectedValue = dependencies[fieldName].setting.equalsTo;
            if (parentValue !== expectedValue) {
                parentValue = "";
            }
        }
    }

    return parentValue.length < 1;

}


export const mapFields = (fields, parentId, formHandlers) => Array.from(fields).map((origField, index) => {

    const field = {...origField};

    const {
        control,
        dependenciesList,
        getValues,
        dynamicFields,
        dynamicOptions,
        setDynamicOptions,
        setValue,
        formState
    } = formHandlers;

    const {type, id, name} = field.attributes;
    const fieldName = getFieldNameByKey(name ?? "");
    const allValues = getValues();
    const fieldState = formHandlers.getFieldState(fieldName);
    const dependencies = dependenciesList;

    if (field.hidden !== undefined && dependencies[fieldName]) {
        field.hidden = isFieldHidden(fieldName, dependencies, allValues);
    }

    if (field.hidden && allValues[fieldName] && allValues[fieldName].length > 0) {
        setValue(fieldName, null);
    }

    if (!formElementFactory[type] && field.subElements.length > 0) {

        /*
        Check if one of the sub-elements is visible
         */
        field.hidden = field.hidden && isSubElementVisible(field.subElements, allValues, dependencies, formState) !== true;

        return !field.hidden && <FormComponent
            key={id}
            id={id}
            className={(!parentId ? "form__component form__component--" + type : "form__element__wrapper form__element__wrapper--" + type) + " mt-0"}
            overrideClass={true}
            label={field.label}
            style={field.hidden && !fieldState.error ? {display: "none"} : {}}
            element={<div className={!parentId ? "form__component__column w-100" : ""}>
                {mapFields(field.subElements, id ?? index.toString(), formHandlers)}
            </div>}
        />

    } else if (!formElementFactory[type]) {

        return !field.hidden && <FormComponent
            label={field.label}
            id={id}
            className={"mb-3"}
            element={<div key={id} className={"form__element"}>
                <span className={"text-danger"}>Type bestaat nog niet...</span>
                {type}</div>}
        />

    }

    const FormElementType = (props) => {
        let Element = formElementFactory[type];
        return <Element key={id} {...props}/>
    }

    let fieldRules = {};
    let label = field.label ? field.label : null;

    if (field.required) {

        fieldRules = {
            ...fieldRules,
            required: "REQUIRED"
        }

        if (label) {
            label = <React.Fragment>
                {label}
                <span className={"ms-1 text-muted"}>*</span>
            </React.Fragment>;
        }
    }

    let attr = {
        ...field.attributes,
        id: id ?? "field_" + index,
        name: fieldName
    };


    if (field.attributes.value && field.attributes.value.length > 0) {
        delete attr.defaultValue;
    }

    if (type === "radio" || type === "checkbox") {
        attr.defaultChecked = false;
        if (allValues[fieldName]) {
            attr.defaultChecked = true;
        }
    }

    if (attr.checked) {
        delete attr.checked;
        if (typeof allValues[fieldName] === "undefined") {
            attr.defaultChecked = true;
        }
    }

    if (type === "bootstrapdatetime") {
        delete attr.type;
    }

    if (type === "email") {
        attr.type = "email";

        fieldRules = {
            ...fieldRules,
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "EMAIL-INVALID"
            }
        }
    }

    if (type === "hidden") {
        attr.type = "hidden";
    }
    if (type === "search") {
        attr.type = "search";
    }
    if (type === "password") {
        attr.type = "password";
    }

    if (attr.maxlength) {
        delete attr.maxlength;
        attr.maxLength = field.attributes.maxlength;
    }
    if (attr.readonly) {
        delete attr.readonly;
        attr.readOnly = true;
    }

    if (type === "radio" || type === "checkbox") {
    }

    if (field.dataType) {
        switch (field.dataType) {
            case "INT":
            case "INTEGER":
                fieldRules = {
                    ...fieldRules,
                    pattern: {
                        ...fieldRules.pattern ?? {},
                        ...{
                            value: new RegExp(/^(?:0|[1-9][0-9]*)$/),
                            message: "NUMBER-INVALID"
                        }
                    },
                }
                break;
            case "FLOAT":
                fieldRules = {
                    ...fieldRules,
                    validate: {
                        isFloat: (v) => {
                            const float = v && parseFloat(v.replace(",", "."));
                            if (v && v.length > 0 && !float) {
                                return "FLOAT-INVALID";
                            } else {
                                return true;
                            }
                        }
                    }
                }
                break;
        }
    }

    if (field.rules && field.rules.length > 0) {

        for (let r = 0; r < field.rules.length; r++) {

            const rule = field.rules[r];

            switch (rule.type?.toUpperCase()) {
                case 'REGEX':

                    const regexStr = rule.config;
                    const regexWithoutBackwardSlashes = regexStr.substring(1, regexStr.length - 1);

                    fieldRules = {
                        ...fieldRules,
                        pattern: {
                            ...fieldRules.pattern ?? {},
                            ...{
                                value: new RegExp(regexWithoutBackwardSlashes),
                                message: {type: "NO-WORD", mess: rule.message}
                            }
                        }
                    }
                    break;
                case 'INTEGER':
                    fieldRules = {
                        ...fieldRules,
                        pattern: {
                            ...fieldRules.pattern ?? {},
                            ...{
                                value: new RegExp(/^(?:0|[1-9][0-9]*)$/),
                                message: {type: "NO-WORD", mess: rule.message}
                            }
                        }
                    }
                    break;
                case 'NOTEMPTY':
                    fieldRules = {
                        ...fieldRules,
                        pattern: {
                            ...fieldRules.pattern ?? {},
                            ...{
                                value: new RegExp(/^\s*$/),
                                message: {type: "NO-WORD", mess: rule.message}
                            }
                        }
                    }
                    break;
                case 'MINNUMERIC':
                    fieldRules = {
                        ...fieldRules,
                        validate: {
                            isNumber: (v) => {
                                const int = parseInt(v);
                                if (v && v.length > 0 && isNaN(int)) {
                                    return "NUMBER-INVALID";
                                } else {
                                    return true;
                                }
                            },
                        },
                        min: {
                            value: parseInt(rule.config),
                            message: {type: "NO-WORD", mess: rule.message}
                        }
                    }
                    break;
                case 'MAXLENGTH':
                    fieldRules = {
                        ...fieldRules,
                        maxLength: {
                            value: rule.config,
                            message: {type: "NO-WORD", mess: rule.message}
                        }
                    }
                    break;
            }

        }
    }

    if (formHandlers.rules && formHandlers.rules[fieldName]) {
        fieldRules = {
            ...fieldRules,
            validate: {
                ...fieldRules.validate ?? {},
                ...formHandlers.rules[fieldName]
            }
        }
    }

    if (formHandlers.onValueChange) {
        const defSave = formHandlers.setValue;
        formHandlers.setValue = (name, value, target) => {
            defSave(name, value);
            formHandlers.onValueChange(getValues(), target);
        }
    }

    return !field.hidden && <Controller name={fieldName} control={control} rules={fieldRules} render={(props) => {

        if (formHandlers.onValueChange) {
            props.field.onChange = (ev) => {
                const target = ev.target;
                const value = target.value;

                if (type === "checkbox") {
                    if (target.checked) {
                        formHandlers.setValue(props.field.name, value, target);
                    } else {
                        formHandlers.setValue(props.field.name, null, target);
                    }
                } else {
                    formHandlers.setValue(props.field.name, value, target);
                }
            }
        }

        if (dynamicFields && dynamicFields[fieldName]) {
            const defaultOnChangeHandler = props.field.onChange;
            delete props.field.onChange;
            attr.onChange = (ev) => {
                defaultOnChangeHandler(ev);
                handleDynamicOptions(ev, fieldName, dynamicFields, setDynamicOptions, dependencies, formHandlers);
            }
        }

        if (type === "hidden") {
            return <FormElement
                name={fieldName}
                element={<FormElementType
                    field={props.field}
                    formHandlers={formHandlers}
                    hasError={props.fieldState.invalid}
                    options={dynamicOptions[fieldName] ?? field.options}
                    content={field.content ?? ""}
                    attr={attr}/>
                }/>
        }

        return <FormComponent key={id} label={label} id={id}
                              className={"form__element__wrapper form__element__wrapper--" + type + " mb-3"}
                              overrideClass={true}
                              style={field.hidden && !props.fieldState.error ? {display: "none"} : {}}
                              element={<FormElement
                                  name={fieldName}
                                  element={<FormElementType
                                      field={props.field}
                                      formHandlers={formHandlers}
                                      hasError={props.fieldState.invalid}
                                      options={dynamicOptions[fieldName] ?? field.options}
                                      content={field.content ?? ""}
                                      attr={attr}/>
                                  }/>}
        />;
    }
    }/>


});


/**
 *
 * @param type
 * @param chainedFields
 * @param v
 * @param formHandlers
 * @param fieldName
 * @return {boolean}
 */
export function applyRule({type, chainedFields, v, formHandlers, fieldName}) {

    let rt = true;

    const {getValues, clearErrors, formState} = formHandlers;
    const {errors} = formState;
    let lastFieldName;

    switch (type.toUpperCase()) {
        case 'UNIQUE':

            if (chainedFields) {
                let values = [];

                Array.from(chainedFields).forEach(field => {

                    const chainedFieldName = getFieldNameByKey(field)
                    lastFieldName = chainedFieldName;
                    const chainedValue = getValues(chainedFieldName);

                    if (errors[chainedFieldName]) {
                        clearErrors(chainedFieldName)
                    }

                    if (chainedValue) {
                        if (values.includes(chainedValue)) {
                            if (chainedFields.length === 2) {
                                rt = chainedFields.join("-AND-").toString().toUpperCase() + "-CANNOT-BE-EQUAL";
                            }

                            return false;
                        }

                        values = [...values, chainedValue.toString()];

                    }


                });

            }
            break;

        case "TEST" :
            rt = "NOPE";
            break;
    }

    return rt;

}

/**
 *
 * @param error
 * @return {{errors: unknown[]}}
 */
export function useFormValidation(error) {

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (error) {
            setErrors(() => {

                let mess;

                if (typeof error.message === "string") {
                    mess = <Word tag={"FORM-ERROR-FIELD-RULE-" + error.message}/>;
                } else {
                    mess = error.message?.mess;
                }

                return [{
                    type: "danger",
                    message: mess
                }];


            });
        } else {
            setErrors([]);
        }
    }, [error, setErrors]);

    return {
        errors: errors.map((error, index) => <FormElementError key={index.toString()} error={error}/>)
    }
}

/**
 *
 * @param attr
 * @param hasError
 * @return {*}
 */
export function useFormErrorClass(attr, hasError) {

    const [className, setClassName] = useState("");

    useEffect(() => {
        setClassName(attr.className ?? "");
        if (hasError) {
            setClassName(attr.className + " border border-danger")
        } else {
            setClassName(attr.className);
        }
    }, [hasError, attr.className])

    if (attr.className) {
        delete attr.className;
    }

    return className;

}

/**
 *
 * @param ev
 * @param fieldName
 * @param dynamicFields
 * @param setDynamicOptions
 * @param dependencies
 * @param formHandlers
 */
const handleDynamicOptions = (ev, fieldName, dynamicFields, setDynamicOptions, dependencies, formHandlers) => {

    const elValue = ev.target.value;
    const {setValue} = formHandlers;

    if (elValue && elValue.length > 0) {
        const uri = dynamicFields[fieldName].uri.replace("[[VALUE]]", ev.target.value);

        axios.get(`${API_URL}${uri}`, API.getHeaders())
            .catch(API.handleNetworkError)
            .then((res) => {
                const {data} = res.data;

                if (dynamicFields[fieldName].fields && dynamicFields[fieldName].fields.length > 0) {
                    Array.from(dynamicFields[fieldName].fields).forEach((dynamicFieldName, index) => {

                        const appendOptions = [
                            {
                                display: "- Maak een keuze -",
                                value: ""
                            },
                            ...data[index].optiongroupItems
                        ];

                        if (!Array.isArray(dynamicFieldName)) {
                            // setValue(dynamicFieldName, null);
                            setDynamicOptions((options) => ({
                                ...options,
                                [dynamicFieldName]: appendOptions
                            }))
                        } else {
                            Array.from(dynamicFieldName).forEach(dynamicFieldName2 => {
                                // setValue(dynamicFieldName2, null);
                                setDynamicOptions((options) => ({
                                    ...options,
                                    [dynamicFieldName2]: appendOptions
                                }))
                            })
                        }
                    })
                }

            })

    } else {

        if (dynamicFields[fieldName].fields && dynamicFields[fieldName].fields.length > 0) {
            Array.from(dynamicFields[fieldName].fields).forEach((dynamicFieldName, index) => {

                if (!Array.isArray(dynamicFieldName)) {

                    // setValue(dynamicFieldName, null);
                    setDynamicOptions((options) => ({
                        ...options,
                        [dynamicFieldName]: []
                    }))
                } else {
                    dynamicFieldName.forEach(dynamicFieldName2 => {

                        // setValue(dynamicFieldName2, null);

                        setDynamicOptions((options) => ({
                            ...options,
                            [dynamicFieldName]: []
                        }))
                    })
                }
            });
        }
    }
}
