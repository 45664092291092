import React from "react";

/**
 *
 * @param props
 * @return {React.DetailedReactHTMLElement<{dangerouslySetInnerHTML: {__html}}, HTMLElement>}
 * @constructor
 */
export function FormElementHtml(props) {
    return React.createElement("div", {
        dangerouslySetInnerHTML: {__html: props.content}
    })
}
