import {useDispatch, useSelector} from "react-redux";
import {selectStatus, setStatus} from "./pwabannerSlice";
import {useEffect, useRef} from "react";

export function Pwabanner() {

    const dispatch = useDispatch();
    const status = useSelector(selectStatus);
    const ref = useRef(null);

    const isMobileSafari = navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)
        && ("standalone" in window.navigator)
        && !window.navigator.standalone
        && ! navigator.userAgent.match('CriOS');
    // const isMobileSafari = true;

    useEffect(() => {

        if (!status) {

            setTimeout((ev) => {

                dispatch(setStatus("visible"));
            }, 1500);
        }

    }, [status, dispatch])

    if (isMobileSafari) {
        return <div className={"pwa pwa--" + status} ref={ref}>
            <div className="pwa__logo">
            </div>
            <div className="pwa__message">
                <div className="pwa__message__title">Varb</div>
                <div className="pwa__message__content">
                    Tik op <span className={"icon"}></span> en selecteer vervolgens <span
                    className={"text-dark"}><strong>Toevoegen aan
                startscherm</strong></span> om deze app op uw apparaat te installeren.
                </div>

            </div>
            <div className="pwa__close" onClick={() => dispatch(setStatus("hidden"))}></div>
        </div>;

    } else {
        return "";
    }

}
