import React, {Component, createRef} from "react";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLongArrowAltUp, faTimes} from '@fortawesome/free-solid-svg-icons'
import {connect} from "react-redux";
import {
    setSelected,
    selectSelected, addAutocomplete, selectAutocomplete
} from "./autocompleteSlice";
import {fetchAutocompleteOptions} from "../../API"
import {Word} from "../../components/Word";
import {selectWord} from "../translate/translateSlice";
import {setList} from "../list/listSlice";

class Autocomplete extends Component {

    /**
     *
     * @param props
     */
    constructor(props) {

        super(props);
        this.state = {
            isLoading: false,
            options: [],
            error: null
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.inputRef = this.props.forwardedRef || createRef();
        this.wrapperRef = this.props.wrapperRef || createRef();

    }

    /**
     *
     * @param isLoading
     */
    setIsLoading(isLoading) {
        this.setState({
            isLoading: isLoading
        });
    }

    /**
     *
     * @param query
     * @return {Promise<void>}
     */
    handleSearch = async (query) => {

        this.setIsLoading(true);

        await fetchAutocompleteOptions(query, (this.props.context || "PLANT"))
            .then((res) => {

                const {results} = res.data;

                if (!results && res.data.message) {
                    throw new Error(res.data.message);
                }

                this.setState({
                    options: results
                });

            });

        this.setIsLoading(false);

    }

    /**
     *
     * @return {Promise<void>}
     */
    componentDidMount() {

        const autocomplete = selectAutocomplete(this.props, this.props.context);

        if (!autocomplete) {
            this.props.dispatch(addAutocomplete({
                identifier: this.props.context
            }));
        }

        const selected = selectSelected(this.props, this.props.context) || [];

        if (selected
            && selected.length > 0
            && selected[0].repeatSearchQuery.length > 0) {

            this.handleSearch(selected[0].repeatSearchQuery);
        }
    }

    /**
     *
     * @returns {JSX.Element}
     */
    render() {

        const {error, options} = this.state;
        const selected = selectSelected(this.props, this.props.context) || [];

        let ref = this.inputRef,
            {hasIcon, triggerPopup, ...props} = this.props;

        if (selected && selected[0]) {
            props = {...props, defaultSelected: selected};
        }

        if (error) {
            return (
                <div>
                    {error}
                </div>
            )
        }

        const filterBy = (option, props) => true;
        const handleSelect = s => {
            this.props.dispatch(setSelected({
                identifier: this.props.context,
                value: s
            }));
        }

        const renderMenuItemChildren = (option) => {

            let qualities = option.qualInput || [],
                qualityTerms = qualities.map((quality) => (
                    <span className="property ms-1">
                        {quality.term}
                    </span>
                )),
                prefix,
                suffix;

            if (option.hasOwnProperty('searchType') === true) {

                switch (option.searchType) {
                    case "ENTITY" :
                        prefix = <span className="property me-1"><Word
                            tag={"LABEL-ACPREFILL-PREFIX-ORG"}/></span>;
                        break;
                    default :
                    case "PLANT" :

                        if (option.hasOwnProperty('ownerName') === true) {
                            suffix = <span className="property ms-1">{option.ownerName}</span>;
                        }
                        break;
                }

            }

            const content = <span>
                {prefix}
                <span className={"label"}>{option.display}</span>
                {suffix}
                {qualityTerms}
            </span>;

            return hasIcon === true ? (
                <span className="d-flex justify-content-between">
                    {content}
                    <span className="icon">
                        <FontAwesomeIcon icon={faLongArrowAltUp} transform={"rotate-45"}/>
                    </span>
                </span>
            ) : content;

        }

        return (
            <div className="form__component form__component--search" ref={this.wrapperRef}>
                <div className="form__element form__element--autocomplete flex-nowrap align-items-center mb-0">

                    <AsyncTypeahead
                        ref={ref}
                        id="terms"
                        labelKey="repeatSearchQuery"
                        filterBy={filterBy}
                        minLength={3}
                        onSearch={this.handleSearch}
                        emptyLabel={this.state.isLoading ? "Laden" : "Er zijn geen resultaten gevonden"}
                        options={options}
                        placeholder={selectWord(this.props, "AUTOCOMPLETE-PLACEHOLER")}
                        renderMenuItemChildren={renderMenuItemChildren}
                        isLoading={this.state.isLoading}
                        onKeyDown={(e) => {

                            /*
                            This is the default onKeyDown event, optionally overridden by props
                             */
                            if (e.keyCode === 13) {

                                this.props.dispatch(setSelected({
                                    identifier: this.props.context,
                                    value: [{
                                        repeatSearchQuery: ref.current.inputNode.value
                                    }]
                                }));

                                this.props.dispatch(setList({
                                    identifier: this.identifier,
                                    triggerRefresh: true
                                }));

                                ref.current.blur();

                                if (props.onSubmit) {
                                    props.onSubmit(e);
                                }

                                return true;

                            }

                        }}

                        {...props}

                    />

                    <div className="form__element--autocomplete--actions position-absolute">
                        <button type={"button"} className="text-black-50 border-0 bg-transparent" onClick={() => {

                            this.props.dispatch(setSelected({
                                identifier: this.props.context,
                                value: false
                            }));

                            this.props.dispatch(setList({
                                identifier: this.identifier,
                                triggerRefresh: true
                            }));

                            ref.current.clear();

                            if (this.props.onClear) {
                                this.props.onClear();
                            }

                        }}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </button>
                    </div>


                </div>
            </div>

        );
    }

}

const MapStateToProps = (state) => ({
    autocomplete: state.autocomplete,
    translate: state.translate
});

export default connect(MapStateToProps)(Autocomplete);
